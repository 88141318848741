import React, { useState } from 'react';
import NextButton from '../component/NextButton';
import './SliderPage.css';
import './Page.css';
import PrevButton from './PrevButton';
import { useMyStore } from '../App';

// const SliderPage = ({ handlePrevPage, backNum, onSubmit }) => {
const SliderPage = ({ handlePrevPage, backNum, onSubmit, handleNextPage }) => {
  const [value, setValue] = useState(0);
  const { setFloatingRate } = useMyStore();

  const handleNextButton = () => {
    setFloatingRate(value > 50 ? 'ExDP' : 'EDP');
    handleNextPage();
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const computeBackgroundColor = (value) => {
    // RGB values for #D4DCEF
    // RGB values for #000000
    // const startRed = 0xd4;
    // const startGreen = 0xdc;
    // const startBlue = 0xef;
    const startRed = 0xff;
    const startGreen = 0xff;
    const startBlue = 0xff;

    // RGB values for #FFE5D1
    // RGB values for #93D46B
    const endRed = 0x93;
    const endGreen = 0xd4;
    const endBlue = 0x6b;

    // Interpolate between the start and end RGB values
    const red = Math.round(startRed + (endRed - startRed) * (value / 100));
    const green = Math.round(
      startGreen + (endGreen - startGreen) * (value / 100)
    );
    const blue = Math.round(startBlue + (endBlue - startBlue) * (value / 100));

    // Convert the RGB values back to HEX
    return `rgb(${red}, ${green}, ${blue})`;
  };

  const backgroundColor = computeBackgroundColor(value);

  // Compute opacity based on the slider value
  // const textOpacity = value / 100;
  // const reverseTextOpacity = 1 - textOpacity;

  // Compute opacity based on the slider value with conditional logic
  const textOpacity = value > 50 ? (value - 50) / 50 : 0;
  const reverseTextOpacity = value < 50 ? (50 - value) / 50 : 0;

  return (
    <div className='page-layout'>
      {/* <div className='page-header'> */}

      <PrevButton onClick={handlePrevPage} />
      <div style={{ height: '6%' }}></div>
      <div className='title'>
        블렌딩이 완성되었어요!
        <br />
        마지막으로 부향률을 골라주세요.
      </div>
      <div style={{ height: '2%' }}></div>
      <div className='sub-title'>부향률은 향료가 포함 된 농도를 의미해요!</div>

      {/* <div className='page-body'> */}
      <div
        style={{
          height: '70%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
        }}
      >
        {/* 슬라이더 */}
        <div
          style={{
            // width: 304,
            width: '80%',
            height: 24,
            border: '1px #161616 solid',
            borderRadius: '15px',
            marginTop: '30px',
            marginBottom: '30px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <input
            type='range'
            min='0'
            max='100'
            value={value}
            onChange={handleChange}
            style={{
              width: '99%',
              // transform: 'translate(-50%, -50%)',
              WebkitAppearance: 'none',
              height: '10px', // Height of the track
              borderRadius: '5px',
              // background: 'transparent', // Make the track transparent
              outline: 'none',
            }}
            className='slider-thumb'
          />
          <div
            style={{
              height: '30px',
              width: '100%',
              transform: 'translate(0%, 50%)',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          ></div>
        </div>

        {/* 그림 */}
        <div
          style={{
            // width: '50%',
            aspectRatio: '1/1',
            height: '60%',
            background: `radial-gradient(circle 150px at center, ${backgroundColor} 0%, #FFFFFF 100%)`,
            // filter: 'blur(10px)',
            backgroundColor: backgroundColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          {/* EDP
          <br />
          지속력과 향기가 밸런스를 이뤄요. */}
          <div
            style={{
              opacity: reverseTextOpacity,
              position: 'absolute',
              textAlign: 'center',
            }}
          >
            EDP
            <br />
            지속력과 향기가 밸런스를 이뤄요.
          </div>
          <div
            style={{
              opacity: textOpacity,
              position: 'absolute',
              textAlign: 'center',
            }}
          >
            ExDP
            <br />
            풍부한 향기와 높은 지속력으로
            <br />
            향료 고유의 매력을 느낄 수 있어요.
          </div>
        </div>
      </div>

      <div className='page-tail'>
        <NextButton
          label={'다음으로'}
          enabled={1}
          onSubmit={handleNextButton}
          // onClick={() => onSubmit(value > 50 ? '빨강' : '파랑')}
        />
      </div>
    </div>
  );
};

export default SliderPage;
