export type Result = {
  gender: 'genderless' | 'male' | 'female';
  name: string;
  keywords: string[];
  combo: string;
  comboPerfume: Perfume;
  description: string;
  perfumes: Perfume[];
};

export type Perfume = {
  name: string;
  engName: string;
  brandName: string;
  brandEngName: string;
  comment: string;
  floatingRate: string;

  cost: number;
  ml: number;
  purchaseURL: string;
};

export const results: Result[] = [
  {
    gender: 'genderless',
    name: 'Colorful-citrus',
    keywords: ['화려한', '신비로운', '프레쉬'],
    combo: 'Soft-citrus',
    comboPerfume: {
      name: '라이온 베흐',
      engName: 'rayon_vert',
      brandName: '바스티유',
      brandEngName: 'bastille',
      comment: '신선한 풀밭을 맨발로 걷는듯한 향',
      floatingRate: 'EDP',
      cost: 158000,
      ml: 50,
      purchaseURL: 'https://brand.naver.com/jovoy/products/8084237409',
    },
    description:
      '마치 휴양지의 노을처럼 상쾌하면서도 신비로운 분위기를 자아내는 향으로, 상큼하지만 차분하면서도 화려한 향수예요. ',
    perfumes: [
      {
        name: '플라멩코 네롤리',
        engName: 'flamenco_neroli',
        brandName: '르 오케스트르',
        brandEngName: "l'orchestre",
        comment: '네롤리와 나무 향이 가득한 아름다운 정원',
        floatingRate: 'EDP',
        cost: 285000,
        ml: 100,
        purchaseURL: 'https://brand.naver.com/jovoy/products/9165462423',
      },
      {
        name: '오-피스트',
        engName: 'hors_piste',
        brandName: '바스티유',
        brandEngName: 'bastille',
        comment: '놀라움을 좋아하는 사람들을 위한 모던한 향',
        floatingRate: 'EDP',
        cost: 158000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/8084225800',
      },
      {
        name: '브로셀리안드',
        engName: 'broceliande',
        brandName: '소라 도라',
        brandEngName: 'soradora',
        comment: '신비롭고 매혹적인 칵테일 향',
        floatingRate: 'ExDP',
        cost: 330000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/9506542601',
      },
    ],
  },
  {
    gender: 'genderless',
    name: 'Classic-citrus',
    keywords: ['청량한', '젊은', '시트러스'],
    combo: 'Bright-citrus',
    comboPerfume: {
      name: '플라멩코 네롤리',
      engName: 'flamenco_neroli',
      brandName: '르 오케스트르',
      brandEngName: "l'orchestre",
      comment: '네롤리와 나무 향이 가득한 아름다운 정원',
      floatingRate: 'EDP',
      cost: 285000,
      ml: 100,
      purchaseURL: 'https://brand.naver.com/jovoy/products/9165462423',
    },
    description:
      '마치 뜨거운 여름 시원한 레몬에이드를 마시는 것처럼 청량한 향으로, 밝고 상큼한 분위기를 드러내기 좋은 향수예요. ',
    perfumes: [
      {
        name: '보-보',
        engName: 'bo_bo',
        brandName: '카너 바르셀로나',
        brandEngName: 'carner barcelona',
        comment: '지중해 즐거운 축제 분위기의 향',
        floatingRate: 'EDP',
        cost: 178000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/7149415746',
      },
      {
        name: '일렉트로 리모네이드',
        engName: 'electro_limonade',
        brandName: '르 오케스트르',
        brandEngName: "l'orchestre",
        comment: '지중해 테라스에서의 칵테일 한 잔',
        floatingRate: 'EDP',
        cost: 285000,
        ml: 100,
        purchaseURL: 'https://brand.naver.com/jovoy/products/9166644042',
      },
      {
        name: '테니스 클럽',
        engName: 'tennis_club',
        brandName: '카너 바르셀로나',
        brandEngName: 'carner barcelona',
        comment: '소나무와 바다 바람이 느껴지는 상쾌한 향',
        floatingRate: 'EDP',
        cost: 110000,
        ml: 30,
        purchaseURL: 'https://brand.naver.com/jovoy/products/7525613749',
      },
    ],
  },
  {
    gender: 'genderless',
    name: 'Delight-fruits',
    keywords: ['달콤한', '가벼운', '프루티'],
    combo: 'Sunny-Flower',
    comboPerfume: {
      name: '오스만투스 앙테르디트',
      engName: 'osmanthus_interdite',
      brandName: '퍼퓸 드 엠파이어',
      brandEngName: "parfum d'empire",
      comment: '섬세하고 우아한 오스만투스 향',
      floatingRate: 'EDP',
      cost: 168000,
      ml: 50,
      purchaseURL: 'https://brand.naver.com/jovoy/products/6720987392',
    },
    description:
      '마치 햇빛이 따스히 비치고 꽃이 핀 공원에서 먹는 과일처럼 상쾌한 향으로, 달콤하면서도 산뜻한 분위기를 가진 향수예요. ',
    perfumes: [
      // {
      //   name: '수퍼 문',
      //   engName: 'super_moon',
      //   brandName: '카너 바르셀로나',
      //   brandEngName: 'carner barcelona',
      //   comment: '슈퍼 문을 보던 여름 밤이 떠오르는 상큼한 향',
      //   floatingRate: 'EDP',
      //   cost: undefined, // Price not provided in the data
      //   ml: undefined, // ml not provided
      //   purchaseURL: '',
      // },
      {
        name: '보-보',
        engName: 'bo_bo',
        brandName: '카너 바르셀로나',
        brandEngName: 'carner barcelona',
        comment: '지중해 즐거운 축제 분위기의 향',
        floatingRate: 'EDP',
        cost: 178000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/7149415746',
      },
      {
        name: '플로로',
        engName: 'floro',
        brandName: '제로보암',
        brandEngName: 'jeroboam',
        comment: '달콤한 과일과 머스크의 만남으로 아름다운 향',
        floatingRate: 'ExDP',
        cost: 165000,
        ml: 30,
        purchaseURL: 'https://brand.naver.com/jovoy/products/6882205879',
      },
      {
        name: '아줴무 레 오랑줴',
        engName: 'azemour_les_orangers',
        brandName: '퍼퓸 드 엠파이어',
        brandEngName: "parfum d'empire",
        comment: '모로코의 오렌지 과수원이 떠오르는 향',
        floatingRate: 'EDP',
        cost: 168000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/6721002558',
      },
    ],
  },
  {
    gender: 'genderless',
    name: 'Fresh-green',
    keywords: ['싱그러운', '맑은', '그린'],
    combo: 'Dynamic-Aldehyde',
    comboPerfume: {
      name: '글래디에이터',
      engName: 'gladiator',
      brandName: '소라 도라',
      brandEngName: 'soradora',
      comment: '사랑스럽고 저항할 수 없는 미묘한 향',
      floatingRate: 'ExDP',
      cost: 330000,
      ml: 50,
      purchaseURL: 'https://brand.naver.com/jovoy/products/9506473323',
    },
    description:
      '마치 비 온 뒤 풀밭에서 산책하는 것처럼 생동감 있는 향으로, 시원하면서도 활발한 분위기를 연출하기 좋은 향수예요. ',
    perfumes: [
      {
        name: '라르브레 데 라 꼬네상스',
        engName: 'connaissance',
        brandName: '조보이',
        brandEngName: 'jovoy',
        comment: '한여름 무성하게 자란 무화과나무에서 풍겨오는 향기',
        floatingRate: 'EDP',
        cost: 250000,
        ml: 100,
        purchaseURL: 'https://brand.naver.com/jovoy/products/6510471864',
      },
      {
        name: '라이온 베흐',
        engName: 'rayon_vert',
        brandName: '바스티유',
        brandEngName: 'bastille',
        comment: '신선한 풀밭을 맨발로 걷는듯한 향',
        floatingRate: 'EDP',
        cost: 158000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/8084237409',
      },
      {
        name: '그레아스크',
        engName: 'greasque',
        brandName: '소라 도라',
        brandEngName: 'soradora',
        comment: '우아하면서도 강인한 민트 향',
        floatingRate: 'ExDP',
        cost: 330000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/9506518532',
      },
    ],
  },
  {
    gender: 'female',
    name: 'Bright-citrus',
    keywords: ['깨끗한', '밝은', '시트러스'],
    combo: 'Classic-citrus',
    comboPerfume: {
      name: '보-보',
      engName: 'bo_bo',
      brandName: '카너 바르셀로나',
      brandEngName: 'carner barcelona',
      comment: '지중해 즐거운 축제 분위기의 향',
      floatingRate: 'EDP',
      cost: 178000,
      ml: 50,
      purchaseURL: 'https://brand.naver.com/jovoy/products/7149415746',
    },
    description:
      '마치 청량한 공기 가득한 정원이 떠오르는 향기로, 밝고 깨끗한 분위기를 전달하기 좋은 향수예요. ',
    perfumes: [
      {
        name: '테니스 클럽',
        engName: 'tennis_club',
        brandName: '카너 바르셀로나',
        brandEngName: 'carner barcelona',
        comment: '소나무와 바다 바람이 느껴지는 상쾌한 향',
        floatingRate: 'EDP',
        cost: 110000,
        ml: 30,
        purchaseURL: 'https://brand.naver.com/jovoy/products/7525613749',
      },
      {
        name: '플뢰르 도',
        engName: "fleur_d'oh",
        brandName: '오탑',
        brandEngName: 'ohtop',
        comment: '경쾌하면서 신선한 예술가의 향',
        floatingRate: 'EDP',
        cost: 285000,
        ml: 100,
        purchaseURL: 'https://brand.naver.com/jovoy/products/9873043377',
      },
      {
        name: '플라멩코 네롤리',
        engName: 'flamenco_neroli',
        brandName: '르 오케스트르',
        brandEngName: "l'orchestre",
        comment: '네롤리와 나무 향이 가득한 아름다운 정원',
        floatingRate: 'EDP',
        cost: 285000,
        ml: 100,
        purchaseURL: 'https://brand.naver.com/jovoy/products/9165462423',
      },
    ],
  },
  {
    gender: 'female',
    name: 'Sunny-Flower',
    keywords: ['우아한', '고급스러운', '플로럴'],
    combo: 'Delight-fruits',
    // comboPerfume: {
    //   name: '수퍼 문',
    //   engName: 'super_moon',
    //   brandName: '카너 바르셀로나',
    //   brandEngName: 'carner barcelona',
    //   comment: '슈퍼 문을 보던 여름 밤이 떠오르는 상큼한 향',
    //   floatingRate: 'EDP',
    //   cost: undefined, // Price not provided in the data
    //   ml: undefined, // ml not provided
    //   purchaseURL: '',
    // },
    comboPerfume: {
      name: '보-보',
      engName: 'bo_bo',
      brandName: '카너 바르셀로나',
      brandEngName: 'carner barcelona',
      comment: '지중해 즐거운 축제 분위기의 향',
      floatingRate: 'EDP',
      cost: 178000,
      ml: 50,
      purchaseURL: 'https://brand.naver.com/jovoy/products/7149415746',
    },
    description:
      '마치 태양빛을 한껏 받고 만개한 꽃처럼 우아한 향기로, 섬세하면서도 고급스러운 향수예요. ',
    perfumes: [
      {
        name: '오스만투스 앙테르디트',
        engName: 'osmanthus_interdite',
        brandName: '퍼퓸 드 엠파이어',
        brandEngName: "parfum d'empire",
        comment: '섬세하고 우아한 오스만투스 향',
        floatingRate: 'EDP',
        cost: 168000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/6720987392',
      },
      {
        name: '플렌 륀느',
        engName: 'plenine_lune',
        brandName: '바스티유',
        brandEngName: 'bastille',
        comment: '달빛처럼 오묘한 향',
        floatingRate: 'EDP',
        cost: 158000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/8084234334',
      },
      {
        name: '비앙코',
        engName: 'bianco',
        brandName: '벤티 콰트로',
        brandEngName: 'venti4',
        comment: '부드럽고 기분 좋은 순수한 향',
        floatingRate: 'EDP',
        cost: 363000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/7998967703',
      },
    ],
  },
  {
    gender: 'female',
    name: 'Silky-Powder',
    keywords: ['부드러운', '관능적인', '플로럴'],
    combo: 'Clean-woody',
    comboPerfume: {
      name: '파라디 뉘',
      engName: 'paradis_nuit',
      brandName: '바스티유',
      brandEngName: 'bastille',
      comment: '절제와 관능이 어우러진 평온한 향',
      floatingRate: 'EDP',
      cost: 158000,
      ml: 50,
      purchaseURL: 'https://brand.naver.com/jovoy/products/9127863126',
    },
    description:
      '마치 해질녘 핑크빛으로 물든 하늘처럼 부드러운 향기로, 여성스러우면서 매력적인 분위기를 보여주기 좋은 향수예요. ',
    perfumes: [
      {
        name: '베소스',
        engName: 'besos',
        brandName: '카너 바르셀로나',
        brandEngName: 'carner barcelona',
        comment: '향신료와 꽃향기의 매혹적인 조화',
        floatingRate: 'EDP',
        cost: 178000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/7149244661',
      },
      {
        name: '로즈 트럼본',
        engName: 'rose_trombone',
        brandName: '르 오케스트르',
        brandEngName: "l'orchestre",
        comment: '매혹적인 장미의 향기를 따라 변화하는 트럼본 멜로디',
        floatingRate: 'EDP',
        cost: 285000,
        ml: 100,
        purchaseURL: 'https://brand.naver.com/jovoy/products/9165507676',
      },
      {
        name: '아이 헤이트 로즈',
        engName: 'i_hate_Rose',
        brandName: '오탑',
        brandEngName: 'ohtop',
        comment: '창립자의 장미에 대한 재해석',
        floatingRate: 'EDP',
        cost: 285000,
        ml: 100,
        purchaseURL: 'https://brand.naver.com/jovoy/products/9873077282',
      },
    ],
  },
  {
    gender: 'male',
    name: 'Soft-citrus',
    keywords: ['열정적인', '밝은', '시트러스'],
    combo: 'Colorful-citrus',
    comboPerfume: {
      name: '플라멩코 네롤리',
      engName: 'flamenco_neroli',
      brandName: '르 오케스트르',
      brandEngName: "l'orchestre",
      comment: '네롤리와 나무 향이 가득한 아름다운 정원',
      floatingRate: 'EDP',
      cost: 285000,
      ml: 100,
      purchaseURL: 'https://brand.naver.com/jovoy/products/9165462423',
    },
    description:
      '마치 따뜻한 태양빛이 아침을 밝히듯 밝은 향기로, 아침 공기처럼 신선하면서도 긍정적인 에너지를 주는 향수예요. ',
    perfumes: [
      {
        name: '라이온 베흐',
        engName: 'rayon_vert',
        brandName: '바스티유',
        brandEngName: 'bastille',
        comment: '신선한 풀밭을 맨발로 걷는듯한 향',
        floatingRate: 'EDP',
        cost: 158000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/8084237409',
      },
      {
        name: '오-피스트',
        engName: 'hors_piste',
        brandName: '바스티유',
        brandEngName: 'bastille',
        comment: '놀라움을 좋아하는 사람들을 위한 모던한 향',
        floatingRate: 'EDP',
        cost: 158000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/8084225800',
      },
      {
        name: '아줴무 레 오랑줴',
        engName: 'azemour_les_orangers',
        brandName: '퍼퓸 드 엠파이어',
        brandEngName: "parfum d'empire",
        comment: '모로코의 오렌지 과수원이 떠오르는 향',
        floatingRate: 'EDP',
        cost: 168000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/6721002558',
      },
    ],
  },
  {
    gender: 'male',
    name: 'Clean-woody',
    keywords: ['차분한', '고급스러운', '우디'],
    combo: 'Silky-Powder',
    comboPerfume: {
      name: '로즈 트럼본',
      engName: 'rose_trombone',
      brandName: '르 오케스트르',
      brandEngName: "l'orchestre",
      comment: '매혹적인 장미의 향기를 따라 변화하는 트럼본 멜로디',
      floatingRate: 'EDP',
      cost: 285000,
      ml: 100,
      purchaseURL: 'https://brand.naver.com/jovoy/products/9165507676',
    },
    description:
      '마치 잔잔한 파도가 치는 바다처럼 차분한 모습을 보여주는 향기로, 특유의 고급스러운 분위기를 표현하기 좋은 향수예요. ',
    perfumes: [
      {
        name: '베스페로',
        engName: 'vespero',
        brandName: '제로보암',
        brandEngName: 'jeroboam',
        comment: '댄디한 어반족들에게 헌정된 남성적인 향',
        floatingRate: 'ExDP',
        cost: 165000,
        ml: 30,
        purchaseURL: 'https://brand.naver.com/jovoy/products/6576283544',
      },
      {
        name: '낫띵 벗 씨 앤드 스카이',
        engName: 'nothing_but_and_sky',
        brandName: '윈느 뉘 노마드',
        brandEngName: 'une nuit nomade',
        comment: '눈 덮인 몬탁에 대한 찬사의 향',
        floatingRate: 'EDP',
        cost: 158000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/7655924831',
      },
      {
        name: '파라디 뉘',
        engName: 'paradis_nuit',
        brandName: '바스티유',
        brandEngName: 'bastille',
        comment: '절제와 관능이 어우러진 평온한 향',
        floatingRate: 'EDP',
        cost: 158000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/9127863126',
      },
    ],
  },
  {
    gender: 'male',
    name: 'Dynamic-Aldehyde',
    keywords: ['역동적인', '남성적인', '푸제르'],
    combo: 'Fresh-green',
    comboPerfume: {
      name: '라르브레 데 라 꼬네상스',
      engName: 'connaissance',
      brandName: '조보이',
      brandEngName: 'jovoy',
      comment: '한여름 무성하게 자란 무화과나무에서 풍겨오는 향기',
      floatingRate: 'EDP',
      cost: 250000,
      ml: 100,
      purchaseURL: 'https://brand.naver.com/jovoy/products/6510471864',
    },
    description:
      '마치 레이싱카처럼 빠르고 역동적인 모습을 보여주는 향기로 특유의 시원하면서 남성성이 잘 드러나는 향수예요.',
    perfumes: [
      {
        name: '보르레이',
        engName: 'vorrei',
        brandName: '벤티 콰트로',
        brandEngName: 'venti4',
        comment: '오묘하고도 강력한 가솔린 향',
        floatingRate: 'EDP',
        cost: 363000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/8116647375',
      },
      {
        name: '글래디에이터',
        engName: 'gladiator',
        brandName: '소라 도라',
        brandEngName: 'soradora',
        comment: '사랑스럽고 저항할 수 없는 미묘한 향',
        floatingRate: 'ExDP',
        cost: 330000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/9506473323',
      },
      {
        name: '베르데',
        engName: 'verde',
        brandName: '벤티 콰트로',
        brandEngName: 'venti4',
        comment: '화이트 플라워와 우디가 부드럽게 어우러진 향',
        floatingRate: 'EDP',
        cost: 363000,
        ml: 50,
        purchaseURL: 'https://brand.naver.com/jovoy/products/7998944266',
      },
    ],
  },
];

type Note = {
  name: string;
  keyword: string;
  description: string;
  imageURL: string;
};

export const noteData: Note[] = [
  {
    name: '가솔린 어코드',
    keyword: '강렬한',
    description: '가상의 향료로 강렬한 가솔린 향기가 납니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/gasoline.png',
  },
  {
    name: '고수',
    keyword: '허브',
    description: '신선한 느낌을 주며, 특유의 허브 향기입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/coriander.png',
  },
  {
    name: '네롤리',
    keyword: '깨끗한',
    description: '깨끗한 느낌을 주며, 살짝 시트러스가 가미된 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/neroli.png',
  },
  {
    name: '레몬',
    keyword: '상큼한',
    description: '청량한 느낌을 주며, 새콤함이 큰 특징입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/lemon.png',
  },
  {
    name: '루바브',
    keyword: '신선한',
    description: '신선한 느낌을 주며, 살짝 흙내음까지 머금은 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/rhubarb.png',
  },
  {
    name: '만다린',
    keyword: '새콤달콤한',
    description: '밝은 분위기를 표현하며, 오렌지보다 좀 더 상큼합니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/mandarine.png',
  },
  {
    name: '말차',
    keyword: '차분한',
    description: '차분한 느낌을 주며, 녹차를 갈아 만든 파우더 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/match.png',
  },
  {
    name: '매그놀리아',
    keyword: '우아한',
    description: '우아한 느낌을 주며, 부드러운 목련 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/magnolia.png',
  },
  {
    name: '머스크',
    keyword: '포근한',
    description: '포근한 느낌을 주며, 살냄새 같은 향기입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/musk.png',
  },
  {
    name: '무화과',
    keyword: '달콤한',
    description: '달콤한 느낌과 짙푸른 향기도 함께 느껴지는 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/fig.png',
  },
  {
    name: '민트',
    keyword: '화한',
    description: '화한 느낌을 주며, 그리니한 허브 향기입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/mint.png',
  },
  {
    name: '바닐라',
    keyword: '달달한',
    description: '달달한 느낌을 주며, 따스한 분위기를 주는 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/vanilla.png',
  },
  {
    name: '바질',
    keyword: '상쾌한',
    description: '상쾌한 느낌을 주며, 살짝 스파이시한 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/basil.png',
  },
  {
    name: '베르가못',
    keyword: '청량한',
    description: '청량한 느낌을 주며, 시트러스한 향기입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/bergamot.png',
  },
  {
    name: '베티버',
    keyword: '중후한',
    description: '중후한 느낌을 주며, 흙내음이 느껴지는 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/vetiver.png',
  },
  {
    name: '블랙커런트',
    keyword: '쌉싸름한',
    description: '쌉싸름한 느낌을 주며, 과일향까지 느껴지는 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/blackcurrant.png',
  },
  {
    name: '샌달우드',
    keyword: '차분한',
    description: '차분한 느낌을 주는 우디향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/sandalwood.png',
  },
  {
    name: '스웨이드',
    keyword: '따스한',
    description: '따스한 느낌을 주는 가상의 향기입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/suede.png',
  },
  {
    name: '시나몬',
    keyword: '톡쏘는',
    description: '톡쏘는 느낌과 함께 디저트 같이 부드러운 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/cinnamon.png',
  },
  {
    name: '시더우드',
    keyword: '포근한',
    description: '포근한 느낌을 주는 건조한 우디향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/cederwood.png',
  },
  {
    name: '아이리스',
    keyword: '차분한',
    description: '차분한 느낌을 주며 버터같은 느낌을 주는 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/iris.png',
  },
  {
    name: '알데히드',
    keyword: '깔끔한',
    description: '깔끔한 느낌을 주며, 비누같이 깨끗한 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/aldehyde.png',
  },
  {
    name: '앰버그리스',
    keyword: '애니멀릭한',
    description: '애니멀릭한 느낌과 우아한 느낌을 주는 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/ambergris.png',
  },
  {
    name: '오렌지',
    keyword: '달콤한',
    description: '달콤하면서도 새콤한 향기입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/orange.png',
  },
  {
    name: '오렌지플라워',
    keyword: '깨끗한',
    description: '깨끗한 느낌과 청량한 느낌을 동시에 주는 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/orangeflower.png',
  },
  {
    name: '오스만투스',
    keyword: '고급스러운',
    description: '고급스러운 느낌을 주는 우아한 꽃 향기입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/osmanthus.png',
  },
  {
    name: '유자',
    keyword: '워터리한',
    description: '워터리한 느낌과 특유의 달콤함이 있는 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/yuzu.png',
  },
  {
    name: '자몽',
    keyword: '달콤쌉싸름',
    description: '달콤쌉싸름 느낌을 주는 향기입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/grapefruit.png',
  },
  {
    name: '자스민',
    keyword: '정제된',
    description: '정제된 느낌을 주는 부케같이 풍성한 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/jasmine.png',
  },
  {
    name: '장미',
    keyword: '우아한',
    description: '우아한 느낌을 주는 깊은 꽃향기입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/rose.png',
  },
  {
    name: '제라늄',
    keyword: '신선한',
    description: '신선한 느낌을 주면서도 풍성한 꽃 향기입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/geranium.png',
  },
  {
    name: '진저',
    keyword: '화한',
    description: '화한 느낌을 주는 스파이시한 향기입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/ginger.png',
  },
  {
    name: '카카오',
    keyword: '꾸덕한',
    description: '꾸덕한 느낌과 달콤한 향기를 동시에 가진 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/cacao.png',
  },
  {
    name: '파츌리',
    keyword: '얼시한',
    description: '얼시한 느낌과 촉촉한 숲 향기를 지녔습니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/patchouli.png',
  },
  {
    name: '핑크페퍼',
    keyword: '스파이시한',
    description: '스파이시한 느낌이지만, 밝은 분위기를 주는 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/pinkpepper.png',
  },
  {
    name: '화이트티',
    keyword: '정적인',
    description: '정적인 느낌을 주면서도 가볍고 섬세한 향입니다.',
    imageURL: process.env.PUBLIC_URL + '/assets/images/note/whitetea.png',
  },
];
