import './App.css';
import './fonts/fonts.css';
import NewSurveyComponent from './component/SurveyComponent copy';
import { create } from 'zustand';
import { viewDataSample } from './data/questionDataSample';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import OrchardResultPage from './component/result/OrchardResultPage';
import './fonts/pretendardvariable.css';

export const useMyStore = create((set, get) => ({
  views: viewDataSample,
  setViews: (views) => set({ views }),
  currentIdx: 0,
  setCurrentIdx: (currentIdx) => set({ currentIdx }),
  answers: {},
  updateAnswer: (question, option, number) => {
    if (number === 1) {
      set((state) => ({ answers: { ...state.answers, [question]: option } }));
    } else {
      // set((state) => ({ answers2: { ...state.answers2, [question]: option } }));
    }
  },

  showEndScreen: false,
  showStartScreen: true,
  setShowStartScreen: (showStartScreen) => set({ showStartScreen }),

  handleNextPage: () => {
    const nextItemIndex = get().currentIdx + 1;
    if (nextItemIndex < get().views.length) {
      set({ currentIdx: nextItemIndex });
    } else {
      set({ showEndScreen: true });
    }
  },

  signatureName: '',
  setSignatureName: (signatureName) => set({ signatureName }),
}));

function App() {
  // const { showStartScreen } = useMyStore();

  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  useEffect(() => {
    ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`);
    ReactGA.send({
      hitType: 'pageview',
      page: 'result',
    });
    window.addEventListener('resize', () => setScreenSize());
    setScreenSize();

    return () => {
      window.removeEventListener('resize', () => setScreenSize());
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path='/'
          element={
            <div className='App'>
              <div className='cover'>
                <NewSurveyComponent />
                {/* <OrchardResultPage /> */}
              </div>
            </div>
          }
        />
        <Route
          path='/result/'
          element={
            <div className='App'>
              <div className='cover' style={{ backgroundColor: 'white' }}>
                {/* <div className='survey-container'>
                  <NewSurveyResult />
                </div> */}
                <OrchardResultPage />
              </div>
            </div>
          }
        />
        <Route path='*' element={<>페이지를 찾을수없습니다.</>} />
      </Routes>
    </Router>
  );
}

export default App;
