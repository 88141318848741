import React, { useState } from 'react';
import './SurveyQuestion.css';
import './ImageOptionsQuestion.css';
import { useMyStore } from '../App';
import PrevButton from './PrevButton';
import NextButton from './NextButton';

function SurveyQuestion({
  view,
  onOptionSelect,
  handlePrevPage,
  handleColorPick,
}) {
  const { type, question, subQuestion, options, imageURL } = view?.data;
  const { optionType, hasImageSpace, optionGrid } = view;

  const { handleNextPage } = useMyStore();
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  // 나중에 데이터참고해서 동적으로 이미지를 가져오도록 수정해야함.
  const ImageUrl =
    process.env.PUBLIC_URL + '/assets/images/loading/loading22.png';

  // function combineAllOptions(options) {
  //   return options.reduce(
  //     (combined, option) => {
  //       // Concatenate the text properties
  //       combined.text += (combined.text ? ', ' : '') + option.text;

  //       // Sum the countArr arrays
  //       if (combined.countArr.length === 0) {
  //         combined.countArr = [...option.countArr];
  //       } else {
  //         combined.countArr = combined?.countArr?.map(
  //           (count, index) => count + (option.countArr[index] || 0)
  //         );
  //       }

  //       return combined;
  //     },
  //     { text: '', countArr: [] }
  //   );
  // }

  // 선택지(option)를 클릭할때 실행됨.
  // const handleOptionSelect = (option) => {
  //   if (optionType === 'COLOR') {
  //     console.log('option', option);
  //     console.log('option?.countArr[0]', option?.countArr[0]);
  //     handleColorPick(option?.countArr[0]);
  //     handleNextPage();
  //     return;
  //   }

  //   if (selectedOptions.includes(option) | (selectedOptions.length >= 2)) {
  //     // Remove the option if it's already selected
  //     setSelectedOptions(selectedOptions.filter((opt) => opt !== option));
  //   } else {
  //     // Add the option if it's not selected
  //     setSelectedOptions([...selectedOptions, option]);
  //   }

  //   // 다음페이지로 이동하지 못하게 막기.
  //   // 2개의 옵션효과를 내기.
  //   // selected를 합쳐서 하나의 option으로 만들기.
  //   const number = type === 'p-question' ? 2 : 1;
  //   if (type !== 'multi-question') {
  //     if (type === 'middle-result')
  //       onOptionSelect('middle-result', option, true, number);
  //     else onOptionSelect(question, option, true, number);
  //   }
  // };
  const handleOptionSelect = (option) => {
    // 선택옵션목록에 현재 선택하려는 옵션이 있으면 제거하고 없으면 추가
    if (option === selectedOption) {
      setSelectedOption(null);
    } else {
      setSelectedOption(option);
    }
  };

  // NextButton을 클릭할때 실행됨.
  // const handleNext = () => {
  //   if (type === 'multi-question') {
  //     if (selectedOptions.length < 2) return;
  //     const combineOption = combineAllOptions(selectedOptions);
  //     const number = type === 'p-question' ? 2 : 1;

  //     if (type === 'middle-result')
  //       onOptionSelect('middle-result', combineOption, true, number);
  //     else onOptionSelect(question, combineOption, true, number);
  //     return;
  //   }
  // };
  const handleNext = () => {
    // question, option, true, 1
    const number = type === 'p-question' ? 2 : 1;
    if (type !== 'multi-question') {
      if (type === 'middle-result')
        onOptionSelect('middle-result', selectedOption, true, number);
      else onOptionSelect(question, selectedOption, true, number);
    }
    setSelectedOption(null);
  };

  return (
    <>
      <PrevButton onClick={handlePrevPage} />
      {/* {!hasImageSpace && <div className='mybox'> </div>} */}
      {/* 질문 공간 */}
      <div className='question'>
        {question}
        <div className='sub-question'>{subQuestion}</div>
      </div>
      {/* 이미지 공간 */}
      {/* {hasImageSpace && (
        <div
          className={
            type === 'middle-result' ? 'middle-result-image' : 'question-image'
          }
        >
          {imageURL && (
            <img
              src={imageURL}
              alt='Question'
              style={{ width: '100%', height: 'auto' }}
            />
          )}
        </div>
      )} */}
      {/* 선택지 공간 */}
      <div className={'option-box' + '-' + optionGrid}>
        {options?.map((option, index) =>
          (optionType === 'ACCORD') | (optionType === 'IMAGE') ||
          optionType === 'COLOR' ? (
            <img
              src={option?.imageURL}
              key={index}
              onClick={() => handleOptionSelect(option)}
              style={{ width: 'auto', height: '80px' }}
              alt={`${index}`}
            />
          ) : (
            <div
              className={
                'select-button' + '-' + optionGrid
                // (selectedOption === option ? '-selected' : '')
                // (selectedOptions.includes(option) && optionGrid === 'col3'
                //   ? '-selected'
                //   : '')
              }
              key={index}
              onClick={() => handleOptionSelect(option)}
              style={{
                backgroundImage:
                  selectedOption === option
                    ? `url(${selectedOption.imageURL})`
                    : 'none',
                backgroundRepeat: 'no-repeat',

                color: selectedOption === option ? 'white' : 'black',
              }}
            >
              {option?.text}
            </div>
          )
        )}
      </div>
      {/* asdf */}
      {/* 다음으로 버튼 */}
      <div className='page-tail'>
        <NextButton
          label={'다음으로'}
          enabled={selectedOption !== null}
          onSubmit={handleNext}
          onClick={undefined}
        />
      </div>
    </>
  );
}

export default SurveyQuestion;
