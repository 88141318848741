import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

const ShareComponent = ({ resultIdx }) => {
  const [currentUrl, setCurrentUrl] = React.useState('');
  const [isChecked, setIsChecked] = useState(false);
  const buttonStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'desired-height',
    cursor: 'pointer',
    borderRadius: 5,
    border: '0.91px #161616 solid',
  };

  // function initKaKao() {
  //   if (window.Kakao) {
  //     const kakao = window.Kakao;
  //     if (!kakao.isInitialized()) kakao.init(process.env.REACT_APP_KAKAO_APP_KEY);
  //     window.Kakao.Share.createDefaultButton({
  //       container: '#kakaotalk-sharing-btn1',
  //       objectType: 'feed',
  //       content: {
  //         title: 'JOVOY Puration',
  //         description: 'JOVOY Perfume Curation, 나에게 맞는 향수를 찾아보세요.',
  //         imageUrl:
  //           window.location.origin + process.env.PUBLIC_URL + `/assets/images/result/thumbnail${resultIdx+1}.jpg`,
  //         link: {
  //           mobileWebUrl: window.location.origin + process.env.PUBLIC_URL,
  //           webUrl: window.location.origin + process.env.PUBLIC_URL,
  //         },
  //       },
  //       buttons: [
  //         {
  //           title: '자세히 보기',
  //           link: {
  //             mobileWebUrl: currentUrl,
  //             webUrl: currentUrl,
  //           },
  //         },
  //         {
  //           title: '추천 받기',
  //           link: {
  //             mobileWebUrl: window.location.origin + process.env.PUBLIC_URL,
  //             webUrl: window.location.origin + process.env.PUBLIC_URL,
  //           },
  //         },
  //       ],
  //     });
  //   }
  // }

  const navigateToRoot = () => {
    // navigate('/');
    window.open(window.location.origin + process.env.PUBLIC_URL, '_self');
    ReactGA.event({
      category: 'Refresh',
      action: 'Refresh - 다시 취향찾기',
      label: '다시 취향찾기 버튼',
      debug_mode: true,
    });
  };

  const openGoogle = (url) => {
    window.open(url, '_blank');
    ReactGA.event({
      category: 'HomePage',
      action: 'HomePage - 조보이 홈페이지',
      label: '조보이 홈페이지 버튼',
      debug_mode: true,
    });
  };

  useEffect(() => {
    // Assuming you want to share the current URL
    setCurrentUrl(window.location.href);
  }, []);

  // useEffect(() => {
  //   initKaKao();
  // }, [currentUrl]);

  const copyLinkToClipboard = async () => {
    if (navigator.share) {
      // Use Web Share API if available
      try {
        await navigator.share({
          title: 'JOVOY Puration - 결과 공유하기',
          url: currentUrl,
        });
        console.log('Link shared successfully');
      } catch (err) {
        console.error('Error sharing the link', err);
      }
    } else if (navigator.clipboard) {
      // Fallback to Clipboard API
      try {
        await navigator.clipboard.writeText(currentUrl);
        alert('링크가 성공적으로 복사되었습니다.');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    } else {
      // Fallback to document.execCommand for older browsers
      try {
        const textarea = document.createElement('textarea');
        textarea.value = currentUrl;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        alert('링크가 복사되었습니다.');
        setIsChecked(true);
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    }
    ReactGA.event({
      category: 'Share',
      action: 'Share - 결과 공유하기',
      label: '결과 공유하기',
      debug_mode: true,
    });
  };

  // const shareOnFacebook = () => {
  //   const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;
  //   window.open(facebookUrl, '_blank');
  // };

  return (
    <div className='result-button-box'>
      <div
        className='button'
        style={{ ...buttonStyle, backgroundColor: '#161616', color: 'white' }}
        onClick={navigateToRoot}
      >
        다시 테스트하기
      </div>
      <div
        className='button'
        style={{ ...buttonStyle, backgroundColor: '#161616', color: 'white' }}
        onClick={() => openGoogle('https://brand.naver.com/jovoy')}
      >
        조보이 홈페이지
      </div>

      {/* <p>공유하기</p> */}
      {/* <div className='share-box'> */}
      {/* <img src={process.env.PUBLIC_URL + '/assets/images/temp/share_logo.png'} alt="" onClick={copyLinkToClipboard} /> */}
      {/* <img src={process.env.PUBLIC_URL + '/assets/images/temp/facebook.png'} alt="" onClick={shareOnFacebook}/> */}
      {/* <img src={process.env.PUBLIC_URL + '/assets/images/temp/insta.png'} alt="" /> */}
      {/* <img src={process.env.PUBLIC_URL + '/assets/images/temp/kakao.png'} alt="" id='kakaotalk-sharing-btn1' /> */}
      {/* </div> */}
      <div className='button' style={buttonStyle} onClick={copyLinkToClipboard}>
        {isChecked ? '링크 복사하기 ✅' : '링크 복사하기 '}
      </div>
      {/* <div className="button" style={buttonStyle} onClick={shareOnFacebook}>페이스북 공유하기</div> */}
      {/* <div className="button" style={buttonStyle} id='kakaotalk-sharing-btn1'>카카오톡 공유하기</div> */}
      {/* Instagram button can guide to copy the link or use Instagram stories if applicable */}
      {/* <button onClick={() => alert('Please copy the link and share it on Instagram.')}>Share on Instagram</button> */}
    </div>
  );
};

export default ShareComponent;
