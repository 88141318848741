import ReactGA from 'react-ga4';

const PerfumeCard = ({
  brandName = '제로보암',
  name = '베스페로',
  floatingRate = 'ExDP',
  ml = 30,
  price = 165000,
  purchaseURL = 'https://brand.naver.com/jovoy/products/6576283544',
  imageURL = '/assets/images/result2/result_clean_woody/vespero.jpg',
  brandImageURL = '/assets/images/result2/brand_logo/jeroboam.png',
  oneLineComment = '새콤달콤한 복숭아 향과 보송한 파우더 향의 조화',
}) => {
  function priceDisplay(price) {
    // Create an instance of Intl.NumberFormat for Korean Won formatting
    // const formatter = new Intl.NumberFormat('ko-KR', {
    //   style: 'currency',
    //   currency: 'KRW',
    //   minimumFractionDigits: 0, // You can adjust this to show decimal places if needed
    // });
    const formatter = new Intl.NumberFormat('ko-KR', {
      maximumFractionDigits: 0, // Ensures no decimal places are shown
      useGrouping: true, // Enables comma as thousands separator
    });
    return formatter.format(price);
  }

  const handleClick = () => {
    // ReactGA.event({
    //   category: 'Perfume',
    //   action: `Perfume - ${name}`,
    //   label: name,
    // });
    if (!purchaseURL) return;
    ReactGA.event({
      category: 'PerfumeCard',
      action: 'PerfumeCard - 향수카드',
      label: name,
    });
    window.open(purchaseURL, '_blank');
  };

  const fontStyle = {
    wordWrap: 'break-word',
    color: '#161616',
  };

  const blurredBackground = {
    filter: 'blur(8px)' /* Adjust the blur level as needed */,
    zIndex: 1 /* Ensures the blur is behind the card but above the normal background */,
  };

  return (
    <div
      style={{
        width: '100%',
        margin: '20px auto',
        // height: 282,
        background: 'rgba(255, 255, 255, 0.70)',
        borderRadius: 5,
        border: '1px #CCCCCC solid',
        // backdropFilter: 'blur(4px)',
        padding: '20px 0px',

        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
      onClick={handleClick}
    >
      <div style={blurredBackground}></div>
      <div
        style={{
          display: 'flex',
          color: '#595959',
          paddingRight: 10,
          paddingLeft: 10,
          paddingTop: 10,
          paddingBottom: 10,
          zIndex: 2,
        }}
      >
        {/* 바틀 이미지 */}
        <div style={{ width: '40%', zIndex: 2 }}>
          <img style={{ width: '100%' }} src={imageURL} alt='' />
        </div>
        <div
          style={{
            width: '60%',
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'space-around',
            alignItems: 'start',
            alignContent: 'start',
            zIndex: 2,
          }}
        >
          <img
            // style={{ width: 100, height: 28, objectFit: 'cover' }}
            // style={{ width: 100, height: '100%', objectFit: 'cover' }}
            style={{ width: 100, objectFit: 'cover', zIndex: 2 }}
            src={brandImageURL}
            alt=''
          />
          <div
            style={{
              fontSize: 12,
              fontWeight: '500',
              ...fontStyle,
              marginTop: 15,
              marginBottom: 8,
              color: '#595959',
              zIndex: 2,
            }}
          >
            {brandName}
          </div>
          <div
            style={{
              color: '#161616',
              fontSize: 15,
              fontWeight: '600',
              ...fontStyle,
              textAlign: 'left',
              marginBottom: 10,
              zIndex: 2,
            }}
          >
            {`${name} ${floatingRate}`}
          </div>
          {/* ml당 가격 */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              zIndex: 2,
            }}
          >
            <div
              style={{
                color: '#161616',
                fontSize: 13,
                fontWeight: '500',
                ...fontStyle,
              }}
            >
              {`${ml}ml  `}
            </div>
            <div
              style={{
                color: '#161616',
                fontSize: 15,
                // fontWeight: 500,
                fontWeight: '500',
                ...fontStyle,
              }}
            >
              {/*Todo. 콤마까지 구현가능???? 165,000 원 */}
              {`${priceDisplay(price)} 원`}
            </div>
          </div>
          <div
            style={{
              ...fontStyle,
              fontSize: 10,
              fontWeight: '500',
              color: '#A9A9A9',
              marginTop: 13,
              zIndex: 2,
            }}
          >
            {purchaseURL ? '카드 클릭 시 상세페이지로 이동합니다.' : ''}
          </div>
        </div>
      </div>
      <div
        style={{
          // margin: '10px auto',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 15,
          marginBottom: 0,
          width: '90%',
          padding: '15px 0px',
          borderRadius: 5,
          overflow: 'hidden',
          border: '0.50px #CCCCCC solid',

          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',

          alignSelf: 'stretch',
          color: '#161616',
          fontSize: 13,
          // fontWeight: '300',
          wordWrap: 'break-word',
        }}
      >
        {oneLineComment}
      </div>
    </div>
    // <div className='perfume-card-container'>
    // </div>
  );
};
// const PerfumeCard = ({
//   brandName,
//   name,
//   imageURL,
//   ment1,
//   purchaseURL,
//   top,
//   middle,
//   base,
//   single,
// }) => {
//   const subStringMaxLen = 20;

//   const handleClick = () => {
//     // ReactGA.event({
//     // 	category: 'PerfumeCard',
//     // 	action: 'Click - 향수카드',
//     // 	label: name,
//     // });
//     // ReactGA.event({
//     // 	category: 'Perfume',
//     // 	action: `Click - ${name}`,
//     // 	label: name,
//     // });
//     window.open(purchaseURL, '_blank');
//   };

//   return (
//     <div className='perfume-card-container'>
//       <div style={{ color: '#606060', fontSize: 12, fontWeight: '300' }}>
//         {brandName}
//       </div>
//       <div
//         style={{
//           color: '#383838',
//           fontSize: 16,
//           fontWeight: '300',
//           marginTop: 12,
//           marginBottom: 16,
//         }}
//       >
//         {name}
//       </div>

//       <img src={imageURL} alt='' style={{ width: 144, height: 135 }} />
//       {/* ment1 */}
//       <div
//         style={{
//           color: '#383838',
//           fontSize: 13,
//           fontWeight: '300',
//           marginTop: 30,
//           marginBottom: 30,
//         }}
//       >
//         {ment1}
//       </div>

//       {/* table */}
//       <div style={{ display: 'flex', flexDirection: 'column' }}>
//         {top && (
//           <div
//             style={{
//               justifyContent: 'flex-start',
//               alignItems: 'flex-start',
//               gap: 40,
//               display: 'inline-flex',
//             }}
//           >
//             <div
//               className='Top'
//               style={{ color: '#383838', fontSize: 11, fontWeight: '400' }}
//             >
//               Top
//             </div>
//             <div style={{ color: '#383838', fontSize: 11, fontWeight: '400' }}>
//               {top?.substring(0, subStringMaxLen)}
//             </div>
//           </div>
//         )}
//         {middle && (
//           <div
//             style={{
//               justifyContent: 'flex-start',
//               alignItems: 'flex-start',
//               gap: 29,
//               display: 'inline-flex',
//             }}
//           >
//             <div
//               className='Heart'
//               style={{ color: '#383838', fontSize: 11, fontWeight: '400' }}
//             >
//               Heart
//             </div>
//             <div style={{ color: '#383838', fontSize: 11, fontWeight: '400' }}>
//               {middle?.substring(0, subStringMaxLen)}
//             </div>
//           </div>
//         )}
//         {base && (
//           <div
//             style={{
//               justifyContent: 'flex-start',
//               alignItems: 'flex-start',
//               gap: 33,
//               display: 'inline-flex',
//             }}
//           >
//             <div
//               className='Base'
//               style={{ color: '#383838', fontSize: 11, fontWeight: '400' }}
//             >
//               Base
//             </div>
//             <div style={{ color: '#383838', fontSize: 11, fontWeight: '400' }}>
//               {base?.substring(0, subStringMaxLen)}
//             </div>
//           </div>
//         )}
//         {single && (
//           <div
//             style={{
//               justifyContent: 'flex-start',
//               alignItems: 'flex-start',
//               gap: 33,
//               display: 'inline-flex',
//             }}
//           >
//             <div
//               className='Base'
//               style={{ color: '#383838', fontSize: 11, fontWeight: '400' }}
//             >
//               Single
//             </div>
//             <div style={{ color: '#383838', fontSize: 11, fontWeight: '400' }}>
//               {single?.substring(0, subStringMaxLen)}
//             </div>
//           </div>
//         )}
//       </div>
//       <br />

//       <div
//         className='Rectangle1891'
//         style={{
//           background: '#383838',
//           width: '100%',
//           marginTop: 20,
//           cursor: 'pointer',
//         }}
//         onClick={handleClick}
//       >
//         <div
//           style={{
//             color: 'white',
//             fontSize: 12,
//             fontWeight: '400',
//             padding: 10,
//           }}
//         >
//           자세히 보기
//         </div>
//       </div>
//     </div>
//   );
// };

export default PerfumeCard;
