import React, { useEffect, useState } from 'react';
import './Narration.css';

const Narration: React.FC<{
  currentItem: any;
  onNext: () => void;
  handlePrevPage: () => void;
}> = ({ currentItem, onNext, handlePrevPage }) => {
  const [fadeIn, setFadeIn] = useState(false);
  const coverImageUrl = currentItem?.imageURL;

  useEffect(() => {
    // Reset fade-in effect
    setFadeIn(false);

    // Start fade-in effect after a brief delay
    const fadeInTimeout = setTimeout(() => {
      setFadeIn(true);
    }, 100); // Adjust delay as needed

    // Move to the next page after 2 seconds
    const nextItemTimeout = setTimeout(() => {
      onNext();
    }, 2000);

    // Clear timeout if the component is unmounted
    return () => {
      clearTimeout(fadeInTimeout);
      clearTimeout(nextItemTimeout);
    };
  }, [currentItem, onNext]);

  return (
    <div
      className='narration-screen'
      style={{
        backgroundImage: `url(${coverImageUrl})`,
        backgroundSize: 'cover',
        // backgroundColor: 'thistle',
      }}
    >
      <div className='white-grid'>
        <div
          style={{ position: 'absolute', top: '50%', width: '100%' }}
          className={`fade-text ${fadeIn ? 'visible' : ''}`}
        >
          {currentItem.text}
        </div>

        <div className='bottom-section'>
          Move To Jovoy’s Garden
          <div className='gauge'>
            <img
              src={process.env.PUBLIC_URL + '/assets/images/loading/bus.png'}
              alt='Moving Bus'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Narration;
