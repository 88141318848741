import './Narration.css';
import { WhiteNextButton } from './NextButton';
import './Page.css';
// import CoverImage from '../../public/assets/images/loading/loading2.png';
// import CoverImage from '/assets/images/loading/loading2.png';
import PrevButton from './PrevButton';

interface StartScreenProps {
  onStart: () => void;
}

function StartScreen({ onStart }: StartScreenProps) {
  const coverImageUrl =
    process.env.PUBLIC_URL + '/assets/images/loading/loading22.png';

  return (
    <div
      className='narration-screen'
      style={{
        backgroundImage: `url(${coverImageUrl})`,
        backgroundSize: 'cover',
      }}
    >
      <div style={{ height: '90%', width: '100%' }}>
        {/* 로고 이미지 */}
        <div
          style={{
            position: 'absolute',
            top: '25%',
            width: '100%',
            // margin: '0px auto',
            // aspectRatio: '1/1',
          }}
        >
          <img
            className='1'
            // style={{ width: 272, height: 272 }}
            style={{ width: '80%', height: '80%' }}
            src={
              process.env.PUBLIC_URL + `/assets/images/temp/jovoy_main_logo.png`
            }
            alt='Jovoy Main Logo'
          />
        </div>
        {/* 멘트 */}
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            color: 'white',
            fontSize: 13,
            fontFamily: 'Noto Sans',
            fontWeight: '300',
            position: 'absolute',
            top: '67%',
          }}
        >
          파리 니치 퍼퓸 편집숍,
          <br />
          조보이의 신비로운 향의 세계로 초대합니다.
          <br />
          조보이 향수 큐레이션으로
          <br />
          나에게 맞는 향수를 찾아보세요.
        </div>
      </div>
      {/* 다음버튼 */}
      <div className='page-tail'>
        <WhiteNextButton onClick={onStart} label={'입장하기'} enabled={true} />
      </div>
    </div>
  );
}

export default StartScreen;
