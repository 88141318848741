import { useState } from 'react';
import './ImageOptionsQuestion.css'; // Import the CSS file for styling
import NextButton from './NextButton';
import PrevButton from './PrevButton';
import './SurveyQuestion.css';
import { noteData } from '../data/perfumeData';

const CheckIcon = ({ className }) => (
  <svg
    className={className}
    width='28'
    height='27'
    viewBox='0 0 28 27'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.32984 8.49562L12.5179 16.5013C12.9694 17.0854 13.8764 16.9957 14.2048 16.3345L21.0586 2.53209'
      stroke='white'
      strokeWidth='4'
      strokeLinecap='round'
    />
  </svg>
);

// const CheckIcon = ({ className }) => (
//   <svg
//     className={className}
//     width='28'
//     height='27'
//     viewBox='0 0 28 27'
//     fill='none'
//     xmlns='http://www.w3.org/2000/svg'
//   >
//     <g filter='url(#filter0_d_3752_2387)'>
//       <path
//         d='M6.32984 8.49562L12.5179 16.5013C12.9694 17.0854 13.8764 16.9957 14.2048 16.3345L21.0586 2.53209'
//         stroke='white'
//         stroke-width='4'
//         stroke-linecap='round'
//       />
//     </g>
//     <defs>
//       <filter
//         id='filter0_d_3752_2387'
//         x='0.330078'
//         y='0.531738'
//         width='26.7285'
//         height='26.3584'
//         filterUnits='userSpaceOnUse'
//         color-interpolation-filters='sRGB'
//       >
//         <feFlood flood-opacity='0' result='BackgroundImageFix' />
//         <feColorMatrix
//           in='SourceAlpha'
//           type='matrix'
//           values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
//           result='hardAlpha'
//         />
//         <feOffset dy='4' />
//         <feGaussianBlur stdDeviation='2' />
//         <feComposite in2='hardAlpha' operator='out' />
//         <feColorMatrix
//           type='matrix'
//           values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
//         />
//         <feBlend
//           mode='normal'
//           in2='BackgroundImageFix'
//           result='effect1_dropShadow_3752_2387'
//         />
//         <feBlend
//           mode='normal'
//           in='SourceGraphic'
//           in2='effect1_dropShadow_3752_2387'
//           result='shape'
//         />
//       </filter>
//     </defs>
//   </svg>
// );

// Todo. ts로 전환할것.
const ImageOptionsQuestion = ({ view, onOptionSelect, handlePrevPage }) => {
  const { type, question, subQuestion, options } = view?.data;
  const { optionType, hasImageSpace, optionGrid } = view;
  const [selectedOption, setSelectedOption] = useState(null);
  const currentNote = selectedOption
    ? noteData.find((note) => note.name === selectedOption.text)
    : undefined;

  // NextButton이 활성화시 사용됨
  const handleOptionSelect = (option) => {
    // 선택옵션목록에 현재 선택하려는 옵션이 있으면 제거하고 없으면 추가
    if (option === selectedOption) {
      setSelectedOption(null);
    } else {
      setSelectedOption(option);
    }
  };

  const handleNext = () => {
    // question, option, true, 1
    const number = type === 'p-question' ? 2 : 1;
    if (type !== 'multi-question') {
      if (type === 'middle-result')
        onOptionSelect('middle-result', selectedOption, true, number);
      else onOptionSelect(question, selectedOption, true, number);
    }
    setSelectedOption(null);
  };

  return (
    <>
      <PrevButton onClick={handlePrevPage} />
      <div style={{ height: '22%' }}>
        <div className='question2'>{question}</div>
        <div className='sub-question2'>
          {subQuestion}
          {/* Top note는 향수에서 첫 인상을 담당해요.
          <br />첫 인사로 가장 기분 좋을것 같은 향료를 수확해 주세요. */}
        </div>
        <div className='comment'>
          <div
            style={{
              textAlign: 'center',
              color: '#161616',
              fontSize: 13.3,
              width: '100%',
              fontWeight: 600,
            }}
          >
            {currentNote ? currentNote.description : '사진을 클릭해보세요.'}
          </div>
        </div>
      </div>
      <div className='options-container'>
        {options?.map((option, index) => (
          <div
            key={index}
            // className='option'
            className={
              'select-button' +
              '-' +
              optionGrid +
              (selectedOption === option ? '-selected' : '')
            }
            onClick={() => handleOptionSelect(option)}
          >
            <img
              // style={{ width: '100%', aspectRatio: '140/179' }}
              style={{
                width: '100%',
                height: '100%',
                aspectRatio: '160/179',
                // aspectRatio: '140/179',
                objectFit: 'cover',
              }}
              src={option.imageURL}
              alt={option.text}
            />
            {selectedOption === option && <CheckIcon className='check-icon' />}
            {option.text}
          </div>
        ))}
      </div>
      {/* <div style={{ backgroundColor: 'blue', height: '12%' }}> */}
      <div className='page-tail'>
        <NextButton
          label={'다음으로'}
          enabled={selectedOption !== null} // 선택시만 활성화되게 조건명시해야함.
          onSubmit={handleNext}
          onClick={undefined}
        />
      </div>
    </>
  );
};

export default ImageOptionsQuestion;
