import { useEffect } from 'react';
import StartScreen from './StartScreen';
import SurveyQuestion from './SurveyQuestion';
import './transitions.css'; // This will contain your CSS for transitions
import './SurveyComponent.css'; // This will contain your CSS for transitions
import Narration from './Narration';
import ImageOptionsQuestion from './ImageOptionsQuestion';
import {
  additionalViewDataSample,
  isNarration,
  isQuestion,
} from '../data/questionDataSample';
import { useMyStore } from '../App';
import { calculateType } from '../util/calculate';
import { useNavigate } from 'react-router-dom';
import SliderPage from './SliderPage';
import NameInputPage from './NameInputPage';
import ReactGA from 'react-ga4';
import { results } from '../data/perfumeData';

function NewSurveyComponent() {
  const {
    views,
    setViews,
    answers,
    updateAnswer,
    showEndScreen,
    showStartScreen,
    setShowStartScreen,
    currentIdx,
    setCurrentIdx,
    handleNextPage,
  } = useMyStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (showEndScreen) {
      // 결과 산출 로직
      const resultTypeIdx = calculateType(answers);
      const top = answers['Top Note'].text;
      const heart = answers['Heart Note'].text;
      const base = answers['Base Note'].text;
      const hidden = answers['Hidden Note'].text;

      // 구글 애널리틱스 이벤트 발생
      ReactGA.event({
        category: 'Result',
        action: `Result`,
        label: '' + results[resultTypeIdx]?.name,
        debug_mode: true,
      });
      ReactGA.event({
        category: 'Top_Note',
        action: `Top_Note`,
        label: '' + top,
        debug_mode: true,
      });
      ReactGA.event({
        category: 'Heart_Note',
        action: `Heart_Note`,
        label: '' + heart,
        debug_mode: true,
      });
      ReactGA.event({
        category: 'Base_Note',
        action: `Base_Note`,
        label: '' + base,
        debug_mode: true,
      });
      ReactGA.event({
        category: 'Hidden_Note',
        action: `Hidden_Note`,
        label: '' + hidden,
        debug_mode: true,
      });
      ReactGA.event({
        category: 'Gender',
        action: `Gender`,
        label:
          answers[
            '조보이 정원에 오신걸 환영 합니다.\n어떤 여름 향수를 만들어 볼까요?'
          ]?.text,
        debug_mode: true,
      });

      // 결과 페이지로 이동
      navigate(
        `/result?resultIdx=${resultTypeIdx}&top=${top}&heart=${heart}&base=${base}&hidden=${hidden}`
      );
    }
  }, [showEndScreen, answers, navigate]);

  const handleOptionSelect = (question, option, next = true, number) => {
    // followUpKey가 존재한다면, 동적으로 view를 추가해준다.
    if (
      !answers?.question &&
      option?.followUpKey &&
      additionalViewDataSample[option?.followUpKey]
    ) {
      const followUpViews = additionalViewDataSample[option?.followUpKey];
      const updatedViews = [
        ...views.slice(0, currentIdx + 1),
        ...followUpViews,
        ...views.slice(currentIdx + 1),
      ];
      setViews(updatedViews);
    }

    // 정답 기입(정답객체에 문제애 대한 선택옵션을 추가한다)
    updateAnswer(question, option, number);

    // 다음 페이지로 이동(선택)
    if (next) handleNextPage();
  };

  // 이전에 추가해둔 followUpKey를 가진 view들을 제거
  const handlePrevPage = () => {
    // 현재 페이지의 정답 지우기(연속으로 지우는 경우 대비)
    const current_question = views[currentIdx]?.data?.question;
    updateAnswer(current_question, undefined, 1);
    updateAnswer(current_question, undefined, 2);

    // 마지막 question인 index를 찾아 해당값으로 currentIdx를 변경
    let prevItemIndex = currentIdx - 1;
    while (prevItemIndex >= 0 && isNarration(views[prevItemIndex].data)) {
      prevItemIndex--;
    }
    if (prevItemIndex < 0) {
      setShowStartScreen(true);
      return;
    }
    setCurrentIdx(prevItemIndex);

    // 이전문제가 followUpKey옵션을 가진 view라면 동적으로 페이지가 추가되었음을 의미하므로, 이를 제거해줘야 함.
    if (views[prevItemIndex]?.data?.options[1]?.followUpKey) {
      const key = answers[views[prevItemIndex]?.data?.question]?.followUpKey;
      // const key2 = answers2[views[prevItemIndex]?.data?.question]?.followUpKey;

      // 알아낸 followUpKey를 additionalViewDataSample에서 찾아서 몇개인지 알아낸다.
      const num = additionalViewDataSample[key]?.length;
      // const num2 = additionalViewDataSample[key2]?.length;
      // 그리고 views의 prevItemIndex+1부터 num개를 제거한다.
      if (num)
        setViews([
          ...views.slice(0, prevItemIndex + 1),
          ...views.slice(prevItemIndex + 1 + num),
        ]);
      // else if (num2)
      //   setViews([
      //     ...views.slice(0, prevItemIndex + 1),
      //     ...views.slice(prevItemIndex + 1 + num2),
      //   ]);
    }
  };

  const currentView = views[currentIdx];
  const currentData = currentView.data;

  if (showStartScreen)
    return <StartScreen onStart={() => setShowStartScreen(false)} />;
  // else if (currentData.type === 'middle-result') {
  //   const typeResult = resultDataSample[calculateType(answers)];
  //   const { middleTitle, middleImgURL } = typeResult;
  //   const middleResultView = {
  //     ...currentView,
  //     data: {
  //       ...currentView?.data,
  //       question: middleTitle,
  //       imageURL: middleImgURL,
  //     },
  //   };
  //   return (
  //     <div className='survey-container'>
  //       <SurveyQuestion
  //         view={middleResultView}
  //         onOptionSelect={handleOptionSelect}
  //         handlePrevPage={handlePrevPage}
  //       />
  //     </div>
  //   );
  // }
  else {
    if (isNarration(currentData)) {
      return (
        <div className='survey-container'>
          <Narration
            currentItem={currentData}
            onNext={handleNextPage}
            handlePrevPage={handlePrevPage}
          />
        </div>
      );
    } else if (currentView?.optionType === 'SLIDE') {
      return (
        <SliderPage
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
        />
      );
    } else if (currentView?.optionType === 'INPUT') {
      return (
        <NameInputPage
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
        />
      );
    } else if (isQuestion(currentData)) {
      return (
        <div className='survey-container'>
          {/* {
            <ProgressBar
              overallProgress={views.length}
              currentProgress={currentIdx + 1}
            />
          } */}
          {currentData.type === 'image-question' ? (
            <ImageOptionsQuestion
              view={currentView}
              onOptionSelect={handleOptionSelect}
              handlePrevPage={handlePrevPage}
            />
          ) : (
            <SurveyQuestion
              view={currentView}
              onOptionSelect={handleOptionSelect}
              // handleColorPick={handleColorPick}
              handlePrevPage={handlePrevPage}
            />
          )}
        </div>
      );
    } else {
      throw Error('Invalid data type');
    }
  }
}

export default NewSurveyComponent;
