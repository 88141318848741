import PrevButton from '../component/PrevButton';
import NextButton from '../component/NextButton';
import './Page.css';
import { useMyStore } from '../App';
import { useState } from 'react';

const NameInputPage = ({ handlePrevPage, handleNextPage }) => {
  const { signatureName, setSignatureName } = useMyStore();

  return (
    <div className='page-layout'>
      <PrevButton onClick={handlePrevPage} />
      <div
        style={{
          height: '15%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            textAlign: 'center',
            color: 'black',
            marginTop: '0.5vh',

            fontSize: 16,
            fontFamily: 'Pretendard',
            fontWeight: '600',
            wordWrap: 'break-word',
            lineHeight: '1.5',
          }}
        >
          마지막으로 여러분이 만든
          <br />
          향수의 이름을 지어주세요!
        </div>
      </div>
      <div className='page-body'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <br />
          <br />
          <br />
          <input
            style={{
              fontSize: 18,
              border: 'none',
              borderBottom: '5px solid #161616',
              outline: 'none',
              textAlign: 'center',
              width: '60%', // Adjust width as needed
              padding: '1vh 0',
              backgroundColor: 'transparent',
              color: 'black',
            }}
            // placeholder='다음으로를 눌러주세요.'
            value={signatureName}
            onChange={(e) => {
              const newValue = e.target.value.slice(0, 15); // 최대 5글자까지만 잘라서 저장
              setSignatureName(newValue);
            }}
          />
          <br />
          <br />
          <br />
        </div>
      </div>

      <div className='page-tail'>
        <NextButton
          onSubmit={handleNextPage}
          label={'다음으로'}
          enabled={signatureName.length !== 0}
        />
      </div>
    </div>
  );
};

export default NameInputPage;
