import React from 'react';
import ShareComponent from './ShareButtons';
import PerfumeCard from './PerfumeCard';
import { useMyStore } from '../../App';
import { useSearchParams } from 'react-router-dom';
import { Result, noteData, results } from '../../data/perfumeData';
import { generateImageFileName } from '../../util/string';
import './NewSurveyResult.css';

const Keyword = ({ keyword }) => {
  const style = {
    width: '78px',
    height: '28px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '3px',
    paddingBottom: '3px',
    borderRadius: '20px',
    border: '0.50px solid #CCCCCC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    textAlign: 'center',
    color: 'black',
    fontSize: '13px',
    fontWeight: '400',
  };
  return <div style={style}>#{keyword}</div>;
};

const OrchardResultPage = () => {
  const { signatureName } = useMyStore();
  const [searchParams] = useSearchParams();
  const userName = signatureName ? signatureName + ' EDP' : '당신의 향수';
  let resultIdx = Number(searchParams.get('resultIdx'));
  const top = noteData.find((note) => note.name === searchParams.get('top'));
  const heart = noteData.find(
    (note) => note.name === searchParams.get('heart')
  );
  const base = noteData.find((note) => note.name === searchParams.get('base'));
  const hidden = noteData.find(
    (note) => note.name === searchParams.get('hidden')
  );

  if (resultIdx === undefined || resultIdx < 0 || resultIdx >= results.length)
    throw Error('resultIdx and accordIdx must be provided in the URL.');

  if (!top || !heart || !base || !hidden)
    throw Error("There's an note error on your URL.");

  const result = results[resultIdx];
  // console.log('result:', result);
  // console.log('userName:', userName);

  // const keywords = ['우아한', '발랄한', '시트러스'];
  const notes = [top, heart, base, hidden];
  const HeadingStyle = {
    color: 'black',
    fontSize: '20px',
    // wordWrap: 'break-word',
  };
  const Heading = () => {
    return (
      <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 30 }}>
        <span style={{ ...HeadingStyle, fontWeight: '700' }}>{userName} </span>
        <span style={{ ...HeadingStyle, fontWeight: '500' }}>
          가 완성됐어요!
        </span>
      </div>
    );
  };

  const KeywordComponent = () => {
    return (
      <>
        {/* 태그 */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            width: '100%',
            justifyContent: 'center',
            // marginTop: 10,
            marginBottom: 30,
          }}
        >
          {result.keywords.map((tag) => (
            <Keyword key={tag} keyword={tag} />
          ))}
        </div>
      </>
    );
  };

  const SingleNoteComponent = ({ note, noteName, imageUrl }) => {
    const topStyle = {
      fontSize: '14px',
      fontWeight: '600',
      wordWrap: 'break-word',
      // color: 'red',
    };

    const lemonStyle = {
      fontSize: '13.5px',
      fontWeight: '400',
      wordWrap: 'break-word',
      // color: 'blue',
    };

    const imageStyle = {
      width: '100%',
      height: 46,
      objectFit: 'cover',
      borderRadius: 5,
    };

    if (note === 'Hidden') {
      return (
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '73%' }}>
            <img style={imageStyle} src={imageUrl} alt='' />
          </div>
          <div style={{ width: '7%' }}></div>
          <div
            style={{
              width: '20%',
              heightL: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              // backgroundColor: 'red',
            }}
          >
            <div style={topStyle}>{note}</div>
            <div style={lemonStyle}>{noteName}</div>
          </div>
        </div>
      );
    }

    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <div
          style={{
            width: '20%',
            heightL: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            // backgroundColor: 'red',
          }}
        >
          <div style={topStyle}>{note}</div>
          <div style={lemonStyle}>{noteName}</div>
        </div>
        <div style={{ width: '7%' }}></div>
        <div style={{ width: '73%' }}>
          <img style={imageStyle} src={imageUrl} alt='' />
        </div>
      </div>
    );
  };

  const NoteComponent = () => {
    // const notes = [
    //   {
    //     note: 'Top',
    //     noteName: '레몬',
    //     imageUrl: '/assets/images/result2/sample_note.png',
    //   },
    //   {
    //     note: 'Heart',
    //     noteName: '장미',
    //     imageUrl: '/assets/images/result2/sample_note.png',
    //   },
    //   {
    //     note: 'Base',
    //     noteName: '머스크',
    //     imageUrl: '/assets/images/result2/sample_note.png',
    //   },
    //   {
    //     note: 'Hidden',
    //     noteName: '바닐라',
    //     imageUrl: '/assets/images/result2/sample_note.png',
    //   },
    // ];
    const temp = ['Top', 'Heart', 'Base', 'Hidden'];

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 24,
          width: '100%',
          margin: '0 auto',
          marginTop: 60,
          marginBottom: 60,
        }}
      >
        {temp.map((note, idx) => (
          <SingleNoteComponent
            // note={temp[idx]}
            key={note}
            note={note}
            noteName={notes[idx].name}
            // imageUrl={noteData.find((data) => data.name === note).imageUrl}
            imageUrl={notes[idx].imageURL}
          />
        ))}
      </div>
    );
  };

  const StyledTextComponent = () => {
    const baseStyle = {
      fontSize: '14px',
      lineHeight: '25px',
      wordWrap: 'break-word',
    };

    const normalStyle = {
      ...baseStyle,
      fontWeight: '400',
    };

    const boldStyle = {
      ...baseStyle,
      fontWeight: '700',
    };

    const semiBoldStyle = {
      ...baseStyle,
      fontWeight: '600',
    };

    return (
      <div
        style={{
          // textAlign: 'center',
          textAlign: 'left',
          width: '100%',
          margin: '0 auto',
          marginTop: 60,
          marginBottom: 90,
        }}
      >
        <span style={normalStyle}>첫 인사는 </span>
        <span style={boldStyle}>{notes[0].name}</span>
        <span style={normalStyle}>의 </span>
        <span style={boldStyle}>
          {noteData.find((note) => note.name === notes[0].name).keyword}
        </span>
        <span style={normalStyle}>
          {' '}
          향기가 먼저 반겨주네요! <br />
          시간이 지나면서,{' '}
        </span>
        <span style={boldStyle}>{notes[1].name}</span>
        <span style={normalStyle}>의 </span>
        <span style={semiBoldStyle}>
          {noteData.find((note) => note.name === notes[1].name).keyword}{' '}
        </span>
        <span style={normalStyle}>향기가 풍성하게 만들어주고, </span>
        <span style={boldStyle}>{notes[2].name}</span>
        <span style={normalStyle}>의 </span>
        <span style={boldStyle}>
          {noteData.find((note) => note.name === notes[2].name).keyword}{' '}
        </span>
        <span style={normalStyle}>
          향기가 마지막까지 책임져주는 향수예요.
          <br />
          그러면서도, 숨어있던{' '}
        </span>
        <span style={boldStyle}>{notes[3].name}</span>
        <span style={normalStyle}>의 </span>
        <span style={boldStyle}>
          {noteData.find((note) => note.name === notes[3].name).keyword}{' '}
        </span>
        <span style={normalStyle}>
          향기가 새어나오는 매력적인 향수를 조향하셨네요.
        </span>
      </div>
    );
  };

  const FragranceComponent = () => {
    const baseStyle = {
      color: 'black',
      fontSize: '14px',
      textAlign: 'left',
      whiteSpace: 'pre-line',
      wordWrap: 'break-word',
    };

    const normalStyle = {
      ...baseStyle,
      fontWeight: '400',
    };

    const semiBoldStyle = {
      ...baseStyle,
      fontWeight: '600',
    };

    return (
      <div
        style={{
          textAlign: 'left',
          width: '100%',
          margin: '25px auto',
          marginTop: 25,
          marginBottom: 90,
          lineHeight: '25px',
        }}
      >
        {/* <span style={normalStyle}>전반적으로 </span>
        <span style={semiBoldStyle}>청량하면서 발랄한</span>
        <span style={normalStyle}>
          {' '}
          분위기를 가진 향수로,
          <br />한 여름에도 무더위를 날려줄 수 있는
          <br />
        </span>
        <span style={semiBoldStyle}>{signatureName} EDP</span>
        <span style={normalStyle}>의 분위기를 느껴보세요!</span> */}
        <span style={normalStyle}>{result.description}</span>
      </div>
    );
  };

  return (
    <div
      style={{
        // width: '100%',
        // backgroundColor: 'red',
        height: '100%',
        position: 'relative',
        overflow: 'auto',
        padding: '30px 28px',
        // WebKit-based browsers (Chrome, Safari, new versions of Edge)
        WebkitScrollbar: {
          display: 'none',
        },
        // Firefox
        scrollbarWidth: 'none',
        // IE and Edge
        msOverflowStyle: 'none',
      }}
    >
      <div style={{ zIndex: 0 }}>
        <img
          alt='cherry'
          src={
            process.env.PUBLIC_URL +
            '/assets/images/result2/background/cherry.png'
          }
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        />
        <img
          alt='orange'
          src={
            process.env.PUBLIC_URL +
            '/assets/images/result2/background/orange.png'
          }
          style={{
            position: 'absolute',
            top: 74,
            left: 0,
          }}
        />
        <img
          alt='grape'
          src={
            process.env.PUBLIC_URL +
            '/assets/images/result2/background/grape.png'
          }
          style={{
            position: 'absolute',
            top: 200,
            right: 0,
            zIndex: 0,
          }}
        />
        <img
          alt='strawberry'
          src={
            process.env.PUBLIC_URL +
            '/assets/images/result2/background/strawberry.png'
          }
          style={{
            position: 'absolute',
            // top: 1200,
            top: 1550,
            left: 0,
          }}
        />
        <img
          alt='cherry'
          src={
            process.env.PUBLIC_URL +
            '/assets/images/result2/background/cherry.png'
          }
          style={{
            position: 'absolute',
            top: 1310 + 350,
            right: 0,
          }}
        />
        <img
          alt='lemon'
          src={
            process.env.PUBLIC_URL +
            '/assets/images/result2/background/lemon.png'
          }
          style={{
            position: 'absolute',
            top: 1810 + 350,
            right: 0,
          }}
        />
        <img
          alt='orange'
          src={
            process.env.PUBLIC_URL +
            '/assets/images/result2/background/orange.png'
          }
          style={{
            position: 'absolute',
            top: 2070 + 350,
            left: 0,
          }}
        />
        <img
          alt='grape'
          src={
            process.env.PUBLIC_URL +
            '/assets/images/result2/background/grape.png'
          }
          style={{
            position: 'absolute',
            top: 2190 + 350,
            right: 0,
          }}
        />
        <img
          alt='lemon'
          src={
            process.env.PUBLIC_URL +
            '/assets/images/result2/background/lemon.png'
          }
          style={{
            position: 'absolute',
            top: 2450 + 350,
            right: 0,
          }}
        />
      </div>
      <Heading />
      <div></div>
      <KeywordComponent />
      <div
        style={{
          // width: '85%',
          width: '100%',
          margin: '0 auto',
          marginTop: 60,
          marginBottom: 60,
          zIndex: 2,
          position: 'relative',
        }}
      >
        <img
          style={{
            width: '100%',
            height: '400px',
            objectFit: 'cover',
            borderRadius: 5,
          }}
          // style={{ width: '90%', borderRadius: 5 }}
          src={
            process.env.PUBLIC_URL +
            `/assets/images/result2/result_${generateImageFileName(
              result.name
            )}/result_${generateImageFileName(result.name)}.jpg`
          }
          alt='sample'
        />
      </div>
      <NoteComponent />
      <StyledTextComponent />

      <div>
        <img
          alt='mood'
          src={
            process.env.PUBLIC_URL +
            `/assets/images/result2/mood/${generateImageFileName(
              result.name
            )}_mood.jpg`
          }
          style={{
            borderRadius: 5,
          }}
        />
      </div>
      <FragranceComponent />

      <div
        style={{
          ...HeadingStyle,
          fontWeight: '600',
          marginTop: 60,
          marginBottom: 40,
          position: 'relative',
        }}
      >
        {userName}와 비슷해요!
      </div>
      {result.perfumes.map((perfume) => (
        <PerfumeCard
          key={perfume.name}
          brandName={perfume.brandName}
          name={perfume.name}
          floatingRate={perfume.floatingRate}
          ml={perfume.ml}
          price={perfume.cost}
          oneLineComment={perfume.comment}
          imageURL={
            process.env.PUBLIC_URL +
            `/assets/images/result2/result_${generateImageFileName(
              result.name
            )}/${perfume.engName}.jpg`
          }
          brandImageURL={
            process.env.PUBLIC_URL +
            `/assets/images/result2/brand_logo/${perfume.brandEngName}.png`
          }
          purchaseURL={perfume.purchaseURL}
        />
      ))}
      <br />
      <br />
      <div
        style={{
          ...HeadingStyle,
          fontWeight: '600',
          marginTop: 40,
          marginBottom: 25,
        }}
      >
        나와 잘 맞는 다른 향수
      </div>
      <img
        style={{
          width: 330,
          height: 94,
          borderRadius: 5,
          marginTop: 20,
          marginBottom: 20,
          zIndex: 2,
        }}
        src={
          process.env.PUBLIC_URL +
          `/assets/images/result2/bottom/bottom_${generateImageFileName(
            result.combo
          )}.jpg`
        }
        alt='banner'
      />
      <PerfumeCard
        key={result.comboPerfume.name}
        brandName={result.comboPerfume.brandName}
        name={result.comboPerfume.name}
        floatingRate={result.comboPerfume.floatingRate}
        ml={result.comboPerfume.ml}
        price={result.comboPerfume.cost}
        oneLineComment={result.comboPerfume.comment}
        imageURL={
          process.env.PUBLIC_URL +
          `/assets/images/result2/result_${generateImageFileName(
            result.combo
          )}/${result.comboPerfume.engName}.jpg`
        }
        brandImageURL={
          process.env.PUBLIC_URL +
          `/assets/images/result2/brand_logo/${result.comboPerfume.brandEngName}.png`
        }
        purchaseURL={result.comboPerfume.purchaseURL}
      />
      <ShareComponent />
    </div>
  );
};

export default OrchardResultPage;
