import React from 'react';
import { useMyStore } from '../App';
import './PrevButton.css';

const PrevButton = ({ onClick }) => {
  const { currentIdx } = useMyStore();

  // if (currentIdx === 0) return null;
  return (
    <div className='prev-button' onClick={onClick}>
      <div className='prev-button-text'>{'< 이전으로'}</div>
    </div>
  );
};

export default PrevButton;
