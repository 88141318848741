import React from 'react';
// import useStore from '../store';

// onClick은 버튼클릭함수를 대체한다.
// onSubmit은 버튼클릭함수와 같이 실행된다.
const NextButton = ({
  label,
  enabled,
  nextNum = 1,
  onClick,
  onSubmit = undefined,
}) => {
  // const { nextPage } = useStore();
  const buttonStyle = {
    backgroundColor: enabled ? '#000000' : '#ADADAD',
    color: '#FFFFFF',
    padding: '16.5px',
    width: '100%',
    borderRadius: '10px',
    border: 'none',
    cursor: enabled ? 'pointer' : 'not-allowed',
    fontSize: '16.5px', // 텍스트 크기 18px
    boxSizing: 'border-box', // 패딩 포함한 크기 계산
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }
    if (!enabled) return;
    if (onSubmit) onSubmit();
  };

  return (
    <div
      style={{
        width: '90%',
        margin: '5px auto',
      }}
    >
      <button style={buttonStyle} onClick={handleClick}>
        {label}
      </button>
    </div>
  );
};

export default NextButton;

export const WhiteNextButton = ({
  label,
  enabled,
  nextNum = 1,
  onClick,
  onSubmit = undefined,
}) => {
  const buttonStyle = {
    padding: '16.5px',
    width: '100%',
    borderRadius: '10px',
    border: 'none',
    cursor: enabled ? 'pointer' : 'not-allowed',
    fontSize: '16.5px', // 텍스트 크기 18px
    boxSizing: 'border-box', // 패딩 포함한 크기 계산

    backgroundColor: 'white',
    color: 'black',
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }
    if (!enabled) return;
    if (onSubmit) onSubmit();
  };

  return (
    <div
      style={{
        width: '90%',
        margin: '5px auto',
      }}
    >
      <button style={buttonStyle} onClick={handleClick}>
        {label}
      </button>
    </div>
  );
};
