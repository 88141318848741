// import { column, resultDataSample } from "../data/perfumeDataSample";

// 정답지를 토대로 타입결과의 idx를 반환하는 함수
export function calculateType(answerArray) {
  let scores = Array(10).fill(0);
  Object.keys(answerArray).forEach((key) => {
    const selectedOption = answerArray[key];
    if (selectedOption?.countArr)
      scores = scores?.map(
        (score, index) => score + selectedOption.countArr[index]
      );
  });
  // const highestIdx = scores.indexOf(Math.max(...scores));
  // return resultDataSample[highestIdx];
  return scores.indexOf(Math.max(...scores));
}

// const getSortedIndexes = (scores) => {
// 	const indexes = scores?.map((score, index) => index);
// 	indexes.sort((a, b) => scores[b] - scores[a]);
// 	return indexes;
// };

// // 정답지를 토대로 향조결과를 반환하는 함수
// export function calculateAccord(answerArray, resultIdx) {
// 	const resultType = resultDataSample[resultIdx];
// 	let scores = Array(10).fill(0);
// 	Object.keys(answerArray).forEach((key) => {
// 		const selectedOption = answerArray[key];
// 		if (selectedOption?.countArr) scores = scores?.map((score, index) => score + selectedOption.countArr[index]);
// 	});

// 	const sortedIndexes = getSortedIndexes(scores);
// 	// let accord;

// 	for(let i=0; i<sortedIndexes.length; i++) {
// 		const matchingIdx = resultType.accords.findIndex(accord => accord.id === column[sortedIndexes[i]]);
// 		if (matchingIdx !== -1) return matchingIdx;
// 		// const matchingElement = resultType.accords.find(accord => accord.id === column[sortedIndexes[i]]);
// 		// if (matchingElement) {
// 		// 	accord = matchingElement;
// 		// 	break;
// 		// } else {
// 		// 	continue;
// 		// }
// 	};
// 	throw new Error('No matching accord found');
// }
