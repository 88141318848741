export type Question = {
  type: string;
  question: string;
  subQuestion?: string;
  options: Option[];
  imageURL?: string;
};

export type Narration = {
  type: string;
  text: string;
  imageURL?: string;
};

export type Option = {
  text: string;
  countArr?: number[];
  followUpKey?: string;
  imageURL?: string;
};

export type View = {
  subTitle?: string;
  hasImageSpace: boolean;
  optionType: 'TEXT' | 'IMAGE' | 'ACCORD' | 'COLOR' | 'SLIDE' | 'INPUT';
  optionGrid: 'col1' | 'col2' | 'col3';
  data: Question | Narration;
};

// Type guard for Question
// Todo. 함수의 반환형이 이해가안댑니다~
export function isQuestion(data: any): data is Question {
  return (data as Question).question !== undefined;
}

// Type guard for Narration
export function isNarration(data: any): data is Narration {
  return (data as Narration).text !== undefined;
}

export const viewDataSample2: View[] = [
  {
    optionType: 'TEXT',
    hasImageSpace: false,
    optionGrid: 'col1',
    data: {
      type: 'question',
      question: '함께하기 앞서,\n향수를 사용한 내 모습을 상상해보세요',
      options: [
        {
          text: '스타처럼 화려하게 빛나는 모습',
          countArr: [0, 1.5, 0, 1.2, 0, 0, 0.9, 0, 0, 0],
        },
        {
          text: '깔끔하고 프로페셔널한 모습',
          countArr: [0, 0, 1.5, 0, 0, 1.3, 0, 1.1, 0, 0],
        },
        {
          text: '신비롭고 지적인 모습',
          countArr: [1.4, 0, 0, 0, 1.2, 0, 0, 0, 0, 1.2],
        },
        {
          text: '누구나 친근하게 다가오는 편안한 모습',
          countArr: [0, 0, 0.9, 0, 0, 1.5, 0, 0, 1.6, 0],
        },
      ],
      // imageURL: process.env.PUBLIC_URL + '/assets/images/question/q1.jpg',
      imageURL: process.env.PUBLIC_URL + '',
    },
  },

  {
    optionType: 'TEXT',
    hasImageSpace: true,
    optionGrid: 'col1',
    data: {
      type: 'narration',
      text: '평소 당신의 모습을 알려주세요!\n평소 모습을 기반으로 향수를 추천해드릴게요.',
    },
  },

  {
    optionType: 'TEXT',
    hasImageSpace: false,
    optionGrid: 'col1',
    data: {
      type: 'question',
      question: '친구들과 만날 때\n주로 몇 명과 만나시나요?',
      options: [
        {
          text: '많은 친구들과 함께 하는 활발한 시간',
          countArr: [0, 1.5, 0.8, 1.1, 0, 0, 1.1, 1.2, 0, 0],
          followUpKey: 'multiTalk',
        },
        {
          text: '2~3명의 친구들과 보내는 조용한 시간',
          countArr: [1.5, 0, 0, 0, 1.2, 1.2, 0, 0, 1.1, 1.3],
          followUpKey: 'singleTalk',
        },
      ],
      imageURL: process.env.PUBLIC_URL + '/assets/images/question/q2.jpg',
    },
  },

  {
    optionType: 'TEXT',
    hasImageSpace: false,
    optionGrid: 'col1',
    data: {
      type: 'narration',
      text: '친구와 함께\n놀러가기로 약속했어요!',
    },
  },

  {
    optionType: 'IMAGE',
    hasImageSpace: true,
    optionGrid: 'col2',
    data: {
      type: 'image-question',
      question: '친구와 가장 방문하고 싶은 곳은 \n어디인가요?',
      options: [
        {
          text: '',
          imageURL: process.env.PUBLIC_URL + '/assets/images/place/temple.jpg',
          followUpKey: 'temple',
        },
        {
          text: '',
          imageURL: process.env.PUBLIC_URL + '/assets/images/place/beach.jpg',
          followUpKey: 'beach',
        },
        {
          text: '',
          imageURL: process.env.PUBLIC_URL + '/assets/images/place/arbor.jpg',
          followUpKey: 'arbor',
        },
        {
          text: '',
          imageURL: process.env.PUBLIC_URL + '/assets/images/place/contest.jpg',
          followUpKey: 'contest',
        },
        {
          text: '',
          imageURL: process.env.PUBLIC_URL + '/assets/images/place/hotel.jpg',
          followUpKey: 'hotel',
        },
        {
          text: '',
          imageURL: process.env.PUBLIC_URL + '/assets/images/place/bar.jpg',
          followUpKey: 'bar',
        },
        // { text: '고즈넉한 사찰', imageURL: process.env.PUBLIC_URL + '/assets/images/place/temple.jpg', followUpKey: 'temple' },
        // { text: '파도 치는 해변', imageURL: process.env.PUBLIC_URL + '/assets/images/place/beach.jpg', followUpKey: 'beach' },
        // { text: '청량한 수목원', imageURL: process.env.PUBLIC_URL + '/assets/images/place/arbor.jpg', followUpKey: 'arbor' },
        // { text: '전시회', imageURL: process.env.PUBLIC_URL + '/assets/images/place/contest.jpg', followUpKey: 'contest' },
        // { text: '고급스러운 호텔', imageURL: process.env.PUBLIC_URL + '/assets/images/place/hotel.jpg', followUpKey: 'hotel' },
        // { text: '힙한 바', imageURL: process.env.PUBLIC_URL + '/assets/images/place/bar.jpg', followUpKey: 'bar' },
      ],
    },
  },

  {
    optionType: 'TEXT',
    hasImageSpace: true,
    optionGrid: 'col1',
    data: {
      type: 'narration',
      text: '제가 잘 파악하고 있는지 알려주세요!',
    },
  },

  {
    optionType: 'TEXT',
    hasImageSpace: true,
    optionGrid: 'col1',
    data: {
      type: 'middle-result',
      question: 'middle-result',
      options: [
        { text: '네, 계속 할게요!', followUpKey: 'additional2' },
        { text: '조금 더 나를 파악해 주세요.', followUpKey: 'additional' },
      ],
      // imageURL: process.env.PUBLIC_URL + '/assets/images/Rectangle 1849.png',
    },
  },
  // 여기서부터 향수점수.
  {
    optionType: 'COLOR',
    hasImageSpace: false,
    optionGrid: 'col3',
    data: {
      type: 'p-question',
      question: '나를 가장 잘 표현하는 색상은 무엇인가요?',
      options: [
        // { text: '분홍', imageURL: process.env.PUBLIC_URL + '/assets/images/color/purple.png', countArr: [0,50,0,50,50,0,0,50,0,50,0,50,0,50,0,50,50,0,50,0], },
        // { text: '살구', imageURL: process.env.PUBLIC_URL + '/assets/images/color/salgu.png', countArr: [0,50,0,50,50,0,0,50,0,50,0,50,0,50,0,50,50,0,50,0], },
        // { text: '갈색', imageURL: process.env.PUBLIC_URL + '/assets/images/color/brown.png', countArr: [1,0,50,0,0,50,50,0,50,0,50,0,50,0,50,0,0,50,0,50], },
        // { text: '연노랑', imageURL: process.env.PUBLIC_URL + '/assets/images/color/Rectangle 1885.png', countArr: [0,50,0,50,50,0,0,50,0,50,0,50,0,50,0,50,50,0,50,0], },
        // { text: '노랑', imageURL: process.env.PUBLIC_URL + '/assets/images/color/Rectangle 1886.png', countArr: [0,50,0,50,50,0,0,50,0,50,0,50,0,50,0,50,50,0,50,0], },
        // { text: '베이지', imageURL: process.env.PUBLIC_URL + '/assets/images/color/Rectangle 1887.png', countArr: [1,0,50,0,0,50,50,0,50,0,50,0,50,0,50,0,0,50,0,50], },
        // { text: '흐린연두', imageURL: process.env.PUBLIC_URL + '/assets/images/color/mint.png', countArr: [1,0,50,0,0,50,50,0,50,0,50,0,50,0,50,0,0,50,0,50], },
        // { text: '연두', imageURL: process.env.PUBLIC_URL + '/assets/images/color/greens.png', countArr: [1,0,50,0,0,50,50,0,50,0,50,0,50,0,50,0,0,50,0,50], },
        // { text: '초록', imageURL: process.env.PUBLIC_URL + '/assets/images/color/green.png', countArr: [1,0,50,0,0,50,50,0,50,0,50,0,50,0,50,0,0,50,0,50], },
        // { text: '흐린하늘', imageURL: process.env.PUBLIC_URL + '/assets/images/color/blues.png', countArr: [1,0,50,0,0,50,50,0,50,0,50,0,50,0,50,0,0,50,0,50], },
        // { text: '하늘', imageURL: process.env.PUBLIC_URL + '/assets/images/color/bluem.png', countArr: [1,0,50,0,0,50,50,0,50,0,50,0,50,0,50,0,0,50,0,50], },
        // { text: '남색', imageURL: process.env.PUBLIC_URL + '/assets/images/color/bluel.png', countArr: [1,0,50,0,0,50,50,0,50,0,50,0,50,0,50,0,0,50,0,50], },
        // { text: '하양', imageURL: process.env.PUBLIC_URL + '/assets/images/color/white.png', countArr: [1,0,50,0,0,50,50,0,50,0,50,0,50,0,50,0,0,50,0,50], },
        // { text: '회색', imageURL: process.env.PUBLIC_URL + '/assets/images/color/gray.png', countArr: [1,0,50,0,0,50,50,0,50,0,50,0,50,0,50,0,0,50,0,50], },
        // { text: '검정', imageURL: process.env.PUBLIC_URL + '/assets/images/color/blacks.png', countArr: [1,0,50,0,0,50,50,0,50,0,50,0,50,0,50,0,0,50,0,50], },
        {
          text: '분홍',
          imageURL: process.env.PUBLIC_URL + '/assets/images/color/purple.jpg',
          countArr: [-1],
        },
        {
          text: '살구',
          imageURL: process.env.PUBLIC_URL + '/assets/images/color/salgu.jpg',
          countArr: [-1],
        },
        {
          text: '갈색',
          imageURL: process.env.PUBLIC_URL + '/assets/images/color/brown.jpg',
          countArr: [0],
        },
        {
          text: '연노랑',
          imageURL:
            process.env.PUBLIC_URL + '/assets/images/color/Rectangle 1885.jpg',
          countArr: [-1],
        },
        {
          text: '노랑',
          imageURL:
            process.env.PUBLIC_URL + '/assets/images/color/Rectangle 1886.jpg',
          countArr: [-1],
        },
        {
          text: '베이지',
          imageURL:
            process.env.PUBLIC_URL + '/assets/images/color/Rectangle 1887.jpg',
          countArr: [0],
        },
        {
          text: '흐린연두',
          imageURL: process.env.PUBLIC_URL + '/assets/images/color/mint.jpg',
          countArr: [0],
        },
        {
          text: '연두',
          imageURL: process.env.PUBLIC_URL + '/assets/images/color/greens.jpg',
          countArr: [0],
        },
        {
          text: '초록',
          imageURL: process.env.PUBLIC_URL + '/assets/images/color/green.jpg',
          countArr: [1],
        },
        {
          text: '흐린하늘',
          imageURL: process.env.PUBLIC_URL + '/assets/images/color/blues.jpg',
          countArr: [0],
        },
        {
          text: '하늘',
          imageURL: process.env.PUBLIC_URL + '/assets/images/color/bluem.jpg',
          countArr: [1],
        },
        {
          text: '남색',
          imageURL: process.env.PUBLIC_URL + '/assets/images/color/bluel.jpg',
          countArr: [1],
        },
        {
          text: '하양',
          imageURL: process.env.PUBLIC_URL + '/assets/images/color/white.jpg',
          countArr: [0],
        },
        {
          text: '회색',
          imageURL: process.env.PUBLIC_URL + '/assets/images/color/gray.jpg',
          countArr: [0],
        },
        {
          text: '검정',
          imageURL: process.env.PUBLIC_URL + '/assets/images/color/blacks.jpg',
          countArr: [0],
        },
      ],
      imageURL: process.env.PUBLIC_URL + '',
    },
  },
  {
    optionType: 'TEXT',
    hasImageSpace: true,
    optionGrid: 'col1',
    data: {
      type: 'narration',
      text: '이번에는 향기 취향에 대해\n간단히 질문드릴게요.',
    },
  },

  {
    optionType: 'TEXT',
    hasImageSpace: false,
    optionGrid: 'col1',
    data: {
      type: 'p-question',
      question: '무엇 때문에 향수를 구매하시나요?',
      options: [
        { text: '내 만족을 위해', countArr: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1] },
        {
          text: '상대방에게 좋은 인상을 주기위해',
          countArr: [0, 1, 1, 0, 0, 0, 0, 1, 1, 1],
        },
      ],
      imageURL: process.env.PUBLIC_URL + '',
    },
  },
  {
    optionType: 'TEXT',
    hasImageSpace: false,
    optionGrid: 'col1',
    data: {
      type: 'p-question',
      question: '현재 향수를 사용하고 계신가요?',
      options: [
        {
          text: '네',
          countArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          followUpKey: 'truePerfume',
        },
        {
          text: '아니요',
          countArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          followUpKey: 'falsePerfume',
        },
      ],
      imageURL: process.env.PUBLIC_URL + '',
    },
  },
  {
    optionType: 'TEXT',
    hasImageSpace: false,
    optionGrid: 'col1',
    data: {
      type: 'p-question',
      question: '마지막으로,\n특별히 선호하지 않는 향이 있으신가요?',
      options: [
        { text: '없음', countArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
        { text: '비릿한 물 향', countArr: [0, -2, -1, 0, 0, 0, 0, -2, 0, 0] },
        { text: '너무 단 향', countArr: [-3, 0, 0, -1, -1, 0, 0, 0, 0, 0] },
        { text: '남자 스킨 향', countArr: [0, 0, -3, -1, -1, 0, 0, 0, 0, 0] },
        { text: '향신료 향', countArr: [-1, 0, 0, -3, -3, -1, -1, 0, 0, 0] },
      ],
      imageURL: process.env.PUBLIC_URL + '',
    },
  },
  {
    optionType: 'TEXT',
    hasImageSpace: true,
    optionGrid: 'col1',
    data: {
      type: 'narration',
      text: '이제, 여정을 마무리 할게요.',
    },
  },
  {
    optionType: 'TEXT',
    hasImageSpace: true,
    optionGrid: 'col1',
    data: {
      type: 'narration',
      text: '잠시 정리 중이에요!',
    },
  },
  {
    optionType: 'TEXT',
    hasImageSpace: true,
    optionGrid: 'col1',
    data: {
      type: 'narration',
      text: '잘 어울리는 향수를 찾은 것 같아요!',
    },
  },
];

export const viewDataSample: View[] = [
  {
    optionType: 'TEXT',
    hasImageSpace: true,
    optionGrid: 'col1',
    data: {
      type: 'narration',
      text: '최고의 재료로 여러분만의\n여름 향수를 조향해 보세요.',
      imageURL: process.env.PUBLIC_URL + '/assets/images/loading/loading22.png',
    },
  },
  {
    optionType: 'TEXT',
    hasImageSpace: false,
    optionGrid: 'col1',
    data: {
      type: 'question',
      // 질문을 바꾸려면 구글애널리틱스 코드도 바꿔야 함에 주의할 것.
      question:
        '조보이 정원에 오신걸 환영 합니다.\n어떤 여름 향수를 만들어 볼까요?',
      options: [
        {
          text: '여성스러운 향수',
          followUpKey: 'female',
          imageURL:
            process.env.PUBLIC_URL +
            '/assets/images/result2/question/female.png',
        },
        {
          text: '남성적인 향수',
          followUpKey: 'male',
          imageURL:
            process.env.PUBLIC_URL + '/assets/images/result2/question/male.png',
        },
        {
          text: '젠더리스한 향수',
          followUpKey: 'genderless',
          imageURL:
            process.env.PUBLIC_URL +
            '/assets/images/result2/question/genderless.png',
        },
      ],
      // imageURL: process.env.PUBLIC_URL + '/assets/images/question/q1.jpg',
      imageURL: process.env.PUBLIC_URL + '',
    },
  },

  {
    optionType: 'TEXT',
    hasImageSpace: true,
    optionGrid: 'col1',
    data: {
      type: 'narration',
      text: '향료를 블렌딩 하고 있어요.',
      imageURL: process.env.PUBLIC_URL + '/assets/images/loading/loading33.png',
    },
  },

  // 23.05.13 피드백으로 인한 수정
  // {
  //   optionType: 'SLIDE',
  //   hasImageSpace: true,
  //   optionGrid: 'col1',
  //   data: {
  //     type: 'question',
  //     question: '블렌딩이 완성되었어요!\n마지막으로 부향률을 골라주세요.',
  //     options: [
  //       { text: 'EDP\n지속력과 향기가 밸런스를 이뤄요.' },
  //       {
  //         text: 'ExDP\n풍부한 향기와 높은 지속력으로\n향료 고유의 매력을 느낄 수 있어요.',
  //       },
  //     ],
  //   },
  // },
  {
    optionType: 'INPUT',
    hasImageSpace: true,
    optionGrid: 'col1',
    data: {
      type: 'question',
      question: '마지막으로 시그니처 향의\n이름을 지어주세요!',
      options: [],
    },
  },

  // ==========================================
];

export type AdditionalQuestions = {
  [key: string]: (Question | Narration)[];
};

export type AdditionalViewDataSample = {
  [key: string]: View[];
};

export const additionalViewDataSample: AdditionalViewDataSample = {
  genderless: [
    {
      optionType: 'TEXT',
      hasImageSpace: false,
      optionGrid: 'col1',
      data: {
        type: 'question',
        question: '언제 사용할 예정이신가요?',
        options: [
          {
            text: '데일리',
            imageURL:
              process.env.PUBLIC_URL +
              '/assets/images/result2/question/daily.png',
          },
          {
            text: '특별한 때',
            imageURL:
              process.env.PUBLIC_URL +
              '/assets/images/result2/question/special.png',
          },
        ],
      },
    },
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'narration',
        text: '직접 조향해 볼까요?',
        imageURL:
          process.env.PUBLIC_URL + '/assets/images/loading/loading11.png',
      },
    },
    // Todo. 이미지 임시로 달아놓았으니 나중에 한번에 추가할 것.
    {
      optionType: 'IMAGE',
      hasImageSpace: true,
      optionGrid: 'col2',
      data: {
        type: 'image-question',
        question: 'Top Note',
        subQuestion:
          'Top note는 향수에서 첫 인상을 담당해요.\n첫 인사로 가장 기분 좋을것 같은 향료를 수확해 주세요.',
        options: [
          {
            text: '레몬',
            countArr: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/lemon.png',
          },
          {
            text: '만다린',
            countArr: [0, 0, 2, 0, 0, 0, 0, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/mandarine.png',
          },
          {
            text: '베르가못',
            countArr: [1, 2, 0, 0, 0, 0, 0, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/bergamot.png',
          },
          {
            text: '바질',
            countArr: [0, 0, 0, 2, 0, 0, 0, 0, 0, 0],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/basil.png',
          },
        ],
      },
    },
    {
      optionType: 'IMAGE',
      hasImageSpace: true,
      optionGrid: 'col2',
      data: {
        type: 'image-question',
        question: 'Heart Note',
        subQuestion:
          'Heart note는 가장 메인이 되는 역할을 해요.\n여러분의 향수를 대표할 수 있는 향료를 수확해 보세요.',
        options: [
          {
            text: '자스민',
            countArr: [1, 1, 2, 0, 0, 0, 0, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/jasmine.png',
          },
          {
            text: '오렌지플라워',
            countArr: [0, 2, 1, 0, 0, 0, 0, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/orangeflower.png',
          },
          {
            text: '시나몬',
            countArr: [2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/cinnamon.png',
          },
          {
            text: '무화과',
            countArr: [0, 0, 0, 2, 0, 0, 0, 0, 0, 0],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/fig.png',
          },
        ],
      },
    },
    {
      optionType: 'IMAGE',
      hasImageSpace: true,
      optionGrid: 'col2',
      data: {
        type: 'image-question',
        question: 'Base Note',
        subQuestion:
          'Base note는 가장 오래남아 안정감을 주는 역할을 해요.\n여러분에게 안정감을 줄것 같은 향료를 수확해 보세요.',
        options: [
          {
            text: '샌달우드',
            countArr: [0, 0, 0, 2, 0, 0, 0, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/sandalwood.png',
          },
          {
            text: '머스크',
            countArr: [0, 2, 1, 0, 0, 0, 0, 0, 0, 0],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/musk.png',
          },
          {
            text: '베티버',
            countArr: [0, 1, 1, 1, 0, 0, 0, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/vetiver.png',
          },
          {
            text: '바닐라',
            countArr: [2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/vanilla.png',
          },
        ],
      },
    },
    {
      optionType: 'IMAGE',
      hasImageSpace: true,
      optionGrid: 'col2',
      data: {
        type: 'image-question',
        question: 'Hidden Note',
        subQuestion:
          '잘 드러나진 않을지 몰라도, 종종 드러나는\n조향사만의 특별 레시피에요 여러분의 히든 노트를 골라주세요!',
        options: [
          {
            text: '아이리스',
            countArr: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/iris.png',
          },
          {
            text: '제라늄',
            countArr: [0, 1, 0, 0, 0, 0, 0, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/geranium.png',
          },
          {
            text: '블랙커런트',
            countArr: [0, 0, 0, 1, 0, 0, 0, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/blackcurrant.png',
          },
          {
            text: '고수',
            countArr: [0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/coriander.png',
          },
        ],
      },
    },
  ],

  female: [
    {
      optionType: 'TEXT',
      hasImageSpace: false,
      optionGrid: 'col1',
      data: {
        type: 'question',
        question: '언제 사용할 예정이신가요?',
        options: [
          {
            text: '데일리',
            imageURL:
              process.env.PUBLIC_URL +
              '/assets/images/result2/question/daily.png',
          },
          {
            text: '특별한 때',
            imageURL:
              process.env.PUBLIC_URL +
              '/assets/images/result2/question/special.png',
          },
        ],
      },
    },
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'narration',
        text: '직접 조향해 볼까요?',
        imageURL:
          process.env.PUBLIC_URL + '/assets/images/loading/loading11.png',
      },
    },
    // Todo. 이미지 임시로 달아놓았으니 나중에 한번에 추가할 것.
    {
      optionType: 'IMAGE',
      hasImageSpace: true,
      optionGrid: 'col2',
      data: {
        type: 'image-question',
        question: 'Top Note',
        subQuestion:
          'Top note는 향수에서 첫 인상을 담당해요.\n첫 인사로 가장 기분 좋을것 같은 향료를 수확해 주세요.',
        options: [
          {
            text: '자몽',
            countArr: [0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/grapefruit.png',
          },
          {
            text: '핑크페퍼',
            countArr: [0, 0, 0, 0, 0, 2, 1, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/pinkpepper.png',
          },
          {
            text: '베르가못',
            countArr: [0, 0, 0, 0, 2, 2, 1, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/bergamot.png',
          },
          {
            text: '화이트티',
            countArr: [0, 0, 0, 0, 0, 1, 0, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/whitetea.png',
          },
        ],
      },
    },
    {
      optionType: 'IMAGE',
      hasImageSpace: true,
      optionGrid: 'col2',
      data: {
        type: 'image-question',
        question: 'Heart Note',
        subQuestion:
          'Heart note는 가장 메인이 되는 역할을 해요.\n여러분의 향수를 대표할 수 있는 향료를 수확해 보세요.',
        options: [
          {
            text: '오스만투스',
            countArr: [0, 0, 0, 0, 0, 2, 0, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/osmanthus.png',
          },
          {
            text: '네롤리',
            countArr: [0, 0, 0, 0, 1, 1, 0, 0, 0, 0],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/neroli.png',
          },
          {
            text: '장미',
            countArr: [0, 0, 0, 0, 0, 0, 2, 0, 0, 0],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/rose.png',
          },
          {
            text: '아이리스',
            countArr: [0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/iris.png',
          },
        ],
      },
    },
    {
      optionType: 'IMAGE',
      hasImageSpace: true,
      optionGrid: 'col2',
      data: {
        type: 'image-question',
        question: 'Base Note',
        subQuestion:
          'Base note는 가장 오래남아 안정감을 주는 역할을 해요.\n여러분에게 안정감을 줄것 같은 향료를 수확해 보세요.',
        options: [
          {
            text: '시더우드',
            countArr: [0, 0, 0, 0, 2, 0, 0, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/cederwood.png',
          },
          {
            text: '머스크',
            countArr: [0, 0, 0, 0, 1, 2, 3, 0, 0, 0],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/musk.png',
          },
          {
            text: '스웨이드',
            countArr: [0, 0, 0, 0, 0, 1, 0, 0, 0, 0],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/suede.png',
          },
          {
            text: '바닐라',
            countArr: [0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/vanilla.png',
          },
        ],
      },
    },
    {
      optionType: 'IMAGE',
      hasImageSpace: true,
      optionGrid: 'col2',
      data: {
        type: 'image-question',
        question: 'Hidden Note',
        subQuestion:
          '잘 드러나진 않을지 몰라도, 종종 드러나는\n조향사만의 특별 레시피에요 여러분의 히든 노트를 골라주세요!',
        options: [
          {
            text: '말차',
            countArr: [0, 0, 0, 0, 0, 1, 0, 0, 0, 0],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/match.png',
          },
          {
            text: '유자',
            countArr: [0, 0, 0, 0, 1, 0, 0, 0, 0, 0],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/yuzu.png',
          },
          {
            text: '루바브',
            countArr: [0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/rhubarb.png',
          },
          {
            text: '매그놀리아',
            countArr: [0, 0, 0, 0, 0, 1, 0, 0, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/magnolia.png',
          },
        ],
      },
    },
  ],

  male: [
    {
      optionType: 'TEXT',
      hasImageSpace: false,
      optionGrid: 'col1',
      data: {
        type: 'question',
        question: '언제 사용할 예정이신가요?',
        options: [
          {
            text: '데일리',
            imageURL:
              process.env.PUBLIC_URL +
              '/assets/images/result2/question/daily.png',
          },
          {
            text: '특별한 때',
            imageURL:
              process.env.PUBLIC_URL +
              '/assets/images/result2/question/special.png',
          },
        ],
      },
    },
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'narration',
        text: '직접 조향해 볼까요?',
        imageURL:
          process.env.PUBLIC_URL + '/assets/images/loading/loading11.png',
      },
    },
    // Todo. 이미지 임시로 달아놓았으니 나중에 한번에 추가할 것.
    {
      optionType: 'IMAGE',
      hasImageSpace: true,
      optionGrid: 'col2',
      data: {
        type: 'image-question',
        question: 'Top Note',
        subQuestion:
          'Top note는 향수에서 첫 인상을 담당해요.\n첫 인사로 가장 기분 좋을것 같은 향료를 수확해 주세요.',
        options: [
          {
            text: '오렌지',
            countArr: [0, 0, 0, 0, 0, 0, 0, 1, 0, 0],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/orange.png',
          },
          {
            text: '바질',
            countArr: [0, 0, 0, 0, 0, 0, 0, 1, 0, 0],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/basil.png',
          },
          {
            text: '베르가못',
            countArr: [0, 0, 0, 0, 0, 0, 0, 0, 1, 2],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/bergamot.png',
          },
          {
            text: '알데히드',
            countArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/aldehyde.png',
          },
        ],
      },
    },
    {
      optionType: 'IMAGE',
      hasImageSpace: true,
      optionGrid: 'col2',
      data: {
        type: 'image-question',
        question: 'Heart Note',
        subQuestion:
          'Heart note는 가장 메인이 되는 역할을 해요.\n여러분의 향수를 대표할 수 있는 향료를 수확해 보세요.',
        options: [
          {
            text: '제라늄',
            countArr: [0, 0, 0, 0, 0, 0, 0, 0, 1, 1],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/geranium.png',
          },
          {
            text: '민트',
            countArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/mint.png',
          },
          {
            text: '진저',
            countArr: [0, 0, 0, 0, 0, 0, 0, 1, 0, 0],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/ginger.png',
          },
          {
            text: '시더우드',
            countArr: [0, 0, 0, 0, 0, 0, 0, 0, 1, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/cederwood.png',
          },
        ],
      },
    },
    {
      optionType: 'IMAGE',
      hasImageSpace: true,
      optionGrid: 'col2',
      data: {
        type: 'image-question',
        question: 'Base Note',
        subQuestion:
          'Base note는 가장 오래남아 안정감을 주는 역할을 해요.\n여러분에게 안정감을 줄것 같은 향료를 수확해 보세요.',
        options: [
          {
            text: '샌달우드',
            countArr: [0, 0, 0, 0, 0, 0, 0, 1, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/sandalwood.png',
          },
          {
            text: '머스크',
            countArr: [0, 0, 0, 0, 0, 0, 0, 0, 1, 0],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/musk.png',
          },
          {
            text: '베티버',
            countArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/vetiver.png',
          },
          {
            text: '파츌리',
            countArr: [0, 0, 0, 0, 0, 0, 0, 0, 1, 1],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/patchouli.png',
          },
        ],
      },
    },
    {
      optionType: 'IMAGE',
      hasImageSpace: true,
      optionGrid: 'col2',
      data: {
        type: 'image-question',
        question: 'Hidden Note',
        subQuestion:
          '잘 드러나진 않을지 몰라도, 종종 드러나는\n조향사만의 특별 레시피에요 여러분의 히든 노트를 골라주세요!',
        options: [
          {
            text: '앰버그리스',
            countArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/ambergris.png',
          },
          {
            text: '고수',
            countArr: [0, 0, 0, 0, 0, 0, 0, 1, 0, 0],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/coriander.png',
          },
          {
            text: '가솔린 어코드',
            countArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/note/gasoline.png',
          },
          {
            text: '카카오',
            countArr: [0, 0, 0, 0, 0, 0, 0, 0, 1, 0],
            imageURL: process.env.PUBLIC_URL + '/assets/images/note/cacao.png',
          },
        ],
      },
    },
  ],

  temple: [
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'question',
        question: '사찰에서 당신은\n어떤 행동을 하고 계신가요?',
        options: [
          {
            text: '풍경과 정취를 즐겨요.',
            countArr: [1, 1, 0, 1, 0, 1, 0, 1, 1, 1],
          },
          {
            text: '문화재를 탐구해요.',
            countArr: [1, 0, 1, 0, 1, 0, 1, 0, 0, 0],
          },
          {
            text: '사찰에서 운영하는 행사에 참여해요.',
            countArr: [0.8, 0.7, 1, 0.7, 1, 0.7, 1, 0.7, 0.7, 0.7],
          },
        ],
        imageURL:
          process.env.PUBLIC_URL + '/assets/images/place/q5_temple_1.jpg',
      },
    },
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'question',
        question:
          '한 아이가 기둥에 낙서하는걸 발견했어요.\n어떻게 반응하실 건가요?',
        options: [
          {
            text: '신경쓰지 않고 지나가요.',
            countArr: [1, 0, 0, 0, 1, 1, 1, 0, 1, 1],
          },
          {
            text: '낙서하면 안된다고 좋은 말로 알려줘요.',
            countArr: [0, 1, 0, 1, 0, 0, 0, 1, 0, 0],
          },
          {
            text: '보호자를 찾아 이야기해요.',
            countArr: [0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
        imageURL:
          process.env.PUBLIC_URL + '/assets/images/place/q5_temple_2.jpg',
      },
    },
  ],
  beach: [
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'question',
        question: '해변에서 어떤 행동을 하고 계시나요?',
        options: [
          {
            text: '서핑을 하기 위해 준비해요',
            countArr: [1, 0, 0.8, 0, 1, 0, 1, 0, 0, 0],
          },
          {
            text: '오션뷰 카페에서 커피를 마셔요',
            countArr: [1, 1, 0, 1, 0, 1, 0, 1, 1, 1],
          },
          {
            text: '간단히 해수욕을 즐겨요',
            countArr: [0.5, 0.7, 0.3, 0.7, 0.3, 0.7, 0.3, 0.7, 0.7, 0.7],
          },
        ],
        imageURL:
          process.env.PUBLIC_URL + '/assets/images/place/q5_beach_1.jpg',
      },
    },
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'question',
        question: '맛집을 가기로 한 당신, \n대기시간을 어떻게 확인하시나요?',
        options: [
          {
            text: '직접 가서 알아봐요',
            countArr: [0, 1, 0, 0, 1, 1, 1, 1, 0, 1],
          },
          {
            text: '예상 대기시간을 미리 서칭해봐요',
            countArr: [1, 0, 1, 1, 0, 0, 1, 1, 1, 0],
          },
        ],
        imageURL:
          process.env.PUBLIC_URL + '/assets/images/place/q5_beach_2.jpg',
      },
    },
  ],
  bar: [
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'question',
        question:
          '메뉴판을 보니 \n어려운 이름들이 나열돼 있습니다.\n이때 어떻게 하실 건가요?',
        options: [
          {
            text: '바텐더에게 위스키를 추천 받아요',
            countArr: [0, 1, 1, 0, 1, 1, 1, 1, 0, 1],
          },
          {
            text: '미리 알아본 위스키를 찾아봐요',
            countArr: [1, 0, 0, 1, 0, 0, 1, 1, 1, 0],
          },
          {
            text: '들어본 적 있는 위스키를 골라요',
            countArr: [1, 0, 0, 1, 0, 0, 1, 1, 1, 0],
          },
          {
            text: '아무거나 느낌대로 골라요',
            countArr: [0, 1, 0, 0, 1, 1, 1, 1, 0, 1],
          },
        ],
        imageURL: process.env.PUBLIC_URL + '/assets/images/place/q5_bar_1.jpg',
      },
    },
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'question',
        question:
          '바텐더가 당신의 앞에서 잔을 깨고\n괜찮냐고 물어봅니다.\n이때 당신의 반응은?',
        options: [
          {
            text: '진심으로 괜찮다며 손사레 쳐요',
            countArr: [1, 1, 0, 1, 0, 1, 0, 1, 1, 1],
          },
          {
            text: '주위를 먼저 확인해요',
            countArr: [1, 0, 1, 0, 1, 0, 1, 0, 0, 0],
          },
        ],
        imageURL: process.env.PUBLIC_URL + '/assets/images/place/q5_bar_2.jpg',
      },
    },
  ],
  hotel: [
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'question',
        question: '호텔에서 식사 중입니다.\n이때 어떤 행동을 하시나요?',
        options: [
          {
            text: '모든 메뉴의 사진을 찍어요.',
            countArr: [1, 1, 0, 1, 0, 1, 0, 1, 1, 1],
          },
          {
            text: '매니저에게 페어링 되는 와인을 추천 받아요.',
            countArr: [0.5, 0.4, 0.6, 0.4, 0.6, 0.4, 0.6, 0.4, 0.4, 0.4],
          },
          {
            text: '음식에 대해 평가하며 대화해요.',
            countArr: [1, 0, 1, 0, 1, 0, 1, 0, 0, 0],
          },
        ],
        imageURL:
          process.env.PUBLIC_URL + '/assets/images/place/q5_hotel_1.jpg',
      },
    },
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'question',
        question:
          '처음 먹어본 음식에서\n예상하지 못한 맛이 납니다.\n어떻게 하실 것 같나요?',
        options: [
          {
            text: '신경 쓰지 않고 그냥 먹어요.',
            countArr: [0, 1, 0, 0, 1, 1, 1, 0, 0, 1],
          },
          {
            text: '메뉴 이름을 검색해봐요.',
            countArr: [1, 0, 1, 1, 0, 0, 0, 1, 1, 0],
          },
          {
            text: '매니저에게 바로 물어봐요.',
            countArr: [0.7, 0.3, 0.5, 0.7, 0.3, 0.3, 0.5, 0.5, 0.7, 0.3],
          },
        ],
        imageURL:
          process.env.PUBLIC_URL + '/assets/images/place/q5_hotel_2.jpg',
      },
    },
  ],
  arbor: [
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'question',
        question:
          '수목원에 도착하자 \n처음보는 식물이 많습니다.\n이때 어떻게 행동하시나요?',
        options: [
          {
            text: '마음에 드는 식물을 보면 이름을 기억해둬요.',
            countArr: [1, 0, 1, 0, 1, 0, 1, 0, 0, 0],
          },
          {
            text: '이곳저곳 사진을 찍으러 다녀요.',
            countArr: [1, 1, 0, 1, 0, 1, 0, 1, 1, 1],
          },
          {
            text: '어떤 향인지 직접 맡아봐요.',
            countArr: [0.5, 0.7, 0.3, 0.7, 0.3, 0.7, 0.3, 0.7, 0.7, 0.7],
          },
        ],
        imageURL:
          process.env.PUBLIC_URL + '/assets/images/place/q5_nature_1.jpg',
      },
    },
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'question',
        question:
          '출입금지된 화단에 들어가 \n꽃을 꺾는 아이가 있습니다. \n어떻게 반응하시나요?',
        options: [
          {
            text: '들어가면 안 된다고 알려줘요',
            countArr: [0, 1, 0, 1, 0, 0, 0, 1, 0, 0],
          },
          {
            text: '관심을 주지않고 지나가요.',
            countArr: [1, 0, 0, 0, 1, 1, 1, 0, 1, 1],
          },
          {
            text: '근처의 관리인을 찾아 알려줘요.',
            countArr: [0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
        imageURL:
          process.env.PUBLIC_URL + '/assets/images/place/q5_nature_2.jpg',
      },
    },
  ],
  contest: [
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'question',
        question:
          '다양한 미술 작품을 볼 수 있는\n전시회에 도착했습니다.\n여기서 당신은 어떤 행동을 하시나요?',
        options: [
          {
            text: '작품 옆 설명을 집중해서 읽어요.',
            countArr: [0.5, 0.4, 0.6, 0.4, 0.6, 0.4, 0.6, 0.4, 0.4, 0.4],
          },
          {
            text: '가이드의 설명을 주의깊게 들어요.',
            countArr: [1, 0, 1, 0, 1, 0, 1, 0, 0, 0],
          },
          {
            text: '작품에 대해 친구와 이야기를 나눠요.',
            countArr: [1, 1, 0, 1, 0, 1, 0, 1, 1, 1],
          },
        ],
        imageURL:
          process.env.PUBLIC_URL + '/assets/images/place/q5_exhib_1.jpg',
      },
    },
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'question',
        question: '작품을 다 둘러봤어요.\n이제 어떤 행동을 하실 것 같나요?',
        options: [
          {
            text: '기억에 남을만한 기념품을 구입해요.',
            countArr: [0, 1, 1, 0, 1, 1, 1, 1, 0, 1],
          },
          {
            text: '다음 스케줄을 위해 빠르게 이동해요.',
            countArr: [1, 0, 1, 1, 0, 0, 1, 1, 1, 0],
          },
        ],
        imageURL:
          process.env.PUBLIC_URL + '/assets/images/place/q5_exhib_2.jpg',
      },
    },
  ],
  singleTalk: [
    {
      optionType: 'TEXT',
      hasImageSpace: false,
      optionGrid: 'col1',
      data: {
        type: 'question',
        question: '친구와 대화할 때,\n주로 어떤 모습이신가요?',
        options: [
          {
            text: '다양한 대화 주제를 먼저 꺼내요',
            countArr: [0, 1.5, 0, 1, 0, 0, 1.5, 0, 0, 0],
          },
          {
            text: '현실적으로 조언 해줘요',
            countArr: [0, 0, 1, 0, 0, 0, 0, 1, 0, 0],
          },
          {
            text: '상상이 가미된 깊이 있는 주제로 대화해요',
            countArr: [1, 0, 0, 0, 1, 0, 0, 0, 0, 1],
          },
          {
            text: '잘 들어주고 따뜻하게 공감해요',
            countArr: [0, 0, 0, 0, 0, 1, 0, 0, 1, 0],
          },
        ],
        imageURL: process.env.PUBLIC_URL + '/assets/images/question/q3.jpg',
      },
    },
  ],
  multiTalk: [
    {
      optionType: 'TEXT',
      hasImageSpace: false,
      optionGrid: 'col1',
      data: {
        type: 'question',
        question: '친구들과 대화할 때,\n주로 어떤 모습이신가요?',
        options: [
          {
            text: '주도적으로 대화를 이끌어가요',
            countArr: [0, 1.3, 0, 1.1, 0, 0, 1.3, 0, 0, 0],
          },
          {
            text: '대화를 정리하고 중재해요',
            countArr: [0, 0, 1.2, 0, 0, 0, 0, 1.5, 0, 0],
          },
          {
            text: '속 깊은 대화를 나누고 있어요',
            countArr: [1.2, 0, 0, 0, 1.5, 0, 0, 0, 0, 1.2],
          },
          {
            text: '모두의 말을 듣고 공감해요',
            countArr: [0, 0, 0, 0, 0, 1.5, 0, 0, 1.5, 0],
          },
        ],
        imageURL: process.env.PUBLIC_URL + '/assets/images/question/q3.jpg',
      },
    },
  ],
  additional: [
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'narration',
        text: '조금 다른 질문을 드려볼게요.',
      },
    },
    {
      optionType: 'IMAGE',
      hasImageSpace: true,
      optionGrid: 'col2',
      data: {
        type: 'image-question',
        question: '가장 기분 좋은 시간대는 언제인가요?',
        options: [
          // { text: '아침', imageURL: process.env.PUBLIC_URL + '/assets/images/time/morning.jpg', countArr: [0,0,0,0,0,0,0,0,0,0] },
          // { text: '낮', imageURL: process.env.PUBLIC_URL + '/assets/images/time/afternoon.jpg', countArr: [0,0,0,0,0,0,0,0,0,0] },
          // { text: '저녁', imageURL: process.env.PUBLIC_URL + '/assets/images/time/night.jpg', countArr: [0,0,0,0,0,0,0,0,0,0] },
          // { text: '밤', imageURL: process.env.PUBLIC_URL + '/assets/images/time/midnight.jpg', countArr: [0,0,0,0,0,0,0,0,0,0] },
          {
            text: '',
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/time/morning.jpg',
            countArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            text: '',
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/time/afternoon.jpg',
            countArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            text: '',
            imageURL: process.env.PUBLIC_URL + '/assets/images/time/night.jpg',
            countArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            text: '',
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/time/midnight.jpg',
            countArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
      },
    },
    // Todo.1. UI에 대한 선택사항을 type이라는 단일타입으로 표현하는 것은 좋은 설계로 보이지 않는다.
    // 2. 2개까지 선택할 수 있게 해야하지만,,, 현재 버튼을 누르면 무조건 다음페이지로 넘어간다.
    {
      optionType: 'TEXT',
      hasImageSpace: false,
      optionGrid: 'col3',
      data: {
        type: 'multi-question',
        question: '나를 가장 잘 표현하는 단어는 무엇인가요?',
        subQuestion: '최대 2개를 골라주세요.',
        options: [
          // 0125 10:40수정
          { text: '자유', countArr: [0, 0, 0, 0, 3, 0, 3, 0, 0, 0] },
          { text: '통찰력', countArr: [3, 0, 0, 0, 3, 0, 3, 0, 0, 1] },
          { text: '도전', countArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
          { text: '효율', countArr: [0, 0, 3, 0, 0, 0, 0, 0, 0, 0] },
          { text: '공감', countArr: [0, 1, 0, 1, 0, 3, 0, 3, 3, 0] },
          { text: '책임감', countArr: [0, 0, 3, 0, 0, 0, 0, 0, 0, 0] },
          { text: '논리', countArr: [0, 0, 3, 0, 1, 0, 1, 0, 0, 0] },
          { text: '예의', countArr: [0, 0, 0, 0, 0, 3, 0, 3, 3, 0] },
          { text: '소통', countArr: [0, 0, 0, 0, 0, 3, 0, 3, 3, 0] },
          { text: '창의', countArr: [3, 3, 0, 3, 3, 0, 3, 0, 0, 3] },
          { text: '열정', countArr: [3, 0, 0, 0, 3, 0, 3, 0, 0, 0] },
          { text: '팀워크', countArr: [0, 0, 3, 0, 0, 0, 0, 0, 0, 0] },
        ],
        imageURL: process.env.PUBLIC_URL + '',
      },
    },
    // 향수점수
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'narration',
        text: '좀 더 잘 이해했어요!',
      },
    },
  ],
  truePerfume: [
    {
      optionType: 'ACCORD',
      hasImageSpace: false,
      optionGrid: 'col1',
      data: {
        question: '어떤 느낌의 향수인가요?',
        type: 'p-question',
        options: [
          {
            text: '스모키',
            countArr: [2, 1, 2, 3, 3, 3, 3, 1, 2, 2],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/accord/accord_smoky.jpg',
          },
          {
            text: '그린',
            countArr: [1, 2, 3, 1, 1, 1, 1, 2, 2, 2],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/accord/accord_green.jpg',
          },
          {
            text: '플로럴',
            countArr: [2, 2, 2, 1, 1, 1, 1, 2, 3, 3],
            imageURL:
              process.env.PUBLIC_URL +
              '/assets/images/accord/accord_floral.jpg',
          },
          {
            text: '시트러스',
            countArr: [1, 3, 1, 1, 1, 1, 2, 2, 2, 2],
            imageURL:
              process.env.PUBLIC_URL +
              '/assets/images/accord/accord_citrus.jpg',
          },
          {
            text: '프루티',
            countArr: [1, 1, 2, 1, 1, 1, 1, 3, 2, 2],
            imageURL:
              process.env.PUBLIC_URL +
              '/assets/images/accord/accord_fruity.jpg',
          },
          {
            text: '스파이시',
            countArr: [1, 1, 2, 3, 3, 2, 2, 1, 2, 2],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/accord/accord_spicy.jpg',
          },
          {
            text: '스위트',
            countArr: [3, 1, 2, 1, 1, 2, 2, 2, 1, 1],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/accord/accord_sweet.jpg',
          },
          {
            text: '머스크',
            countArr: [2, 1, 1, 3, 3, 2, 2, 1, 2, 2],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/accord/accord_musk.jpg',
          },
          {
            text: '비누',
            countArr: [1, 1, 1, 2, 2, 3, 2, 1, 3, 3],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/accord/accord_soap.jpg',
          },
          {
            text: '워터리',
            countArr: [1, 2, 3, 1, 1, 1, 1, 2, 2, 2],
            imageURL:
              process.env.PUBLIC_URL +
              '/assets/images/accord/accord_watery.jpg',
          },
          {
            text: '우디',
            countArr: [2, 1, 2, 1, 1, 3, 3, 1, 1, 1],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/accord/accord_woody.jpg',
          },
        ],
        imageURL: process.env.PUBLIC_URL + '',
      },
    },
    {
      optionType: 'TEXT',
      hasImageSpace: false,
      optionGrid: 'col1',
      data: {
        type: 'p-question',
        question: '지금은 그 향수를 어떻게 느끼시나요?',
        options: [
          { text: '잘 쓰고 있어요.', countArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
          {
            text: '이젠 바꿔보고 싶어요.',
            countArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          // { text: '잘 쓰고 있어요.', countArr: [1,1,0,0,1,1,0,0,1,1,0,0,1,1,0,0,1,1,0,0] },
          // { text: '이젠 바꿔보고 싶어요.', countArr: [0,0,1,1,0,0,1,1,0,0,1,1,0,0,1,1,0,0,1,1] },
        ],
        imageURL: process.env.PUBLIC_URL + '',
      },
    },
  ],
  falsePerfume: [
    {
      optionType: 'ACCORD',
      hasImageSpace: false,
      optionGrid: 'col1',
      data: {
        question: '평소 좋아하는 향을 골라주세요',
        // p-question 허허;
        type: 'p-question',
        options: [
          {
            text: '스모키',
            countArr: [2, 1, 2, 3, 3, 3, 3, 1, 2, 2],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/accord/accord_smoky.jpg',
          },
          {
            text: '그린',
            countArr: [1, 2, 3, 1, 1, 1, 1, 2, 2, 2],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/accord/accord_green.jpg',
          },
          {
            text: '플로럴',
            countArr: [2, 2, 2, 1, 1, 1, 1, 2, 3, 3],
            imageURL:
              process.env.PUBLIC_URL +
              '/assets/images/accord/accord_floral.jpg',
          },
          {
            text: '시트러스',
            countArr: [1, 3, 1, 1, 1, 1, 2, 2, 2, 2],
            imageURL:
              process.env.PUBLIC_URL +
              '/assets/images/accord/accord_citrus.jpg',
          },
          {
            text: '프루티',
            countArr: [1, 1, 2, 1, 1, 1, 1, 3, 2, 2],
            imageURL:
              process.env.PUBLIC_URL +
              '/assets/images/accord/accord_fruity.jpg',
          },
          {
            text: '스파이시',
            countArr: [1, 1, 2, 3, 3, 2, 2, 1, 2, 2],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/accord/accord_spicy.jpg',
          },
          {
            text: '스위트',
            countArr: [3, 1, 2, 1, 1, 2, 2, 2, 1, 1],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/accord/accord_sweet.jpg',
          },
          {
            text: '머스크',
            countArr: [2, 1, 1, 3, 3, 2, 2, 1, 2, 2],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/accord/accord_musk.jpg',
          },
          {
            text: '비누',
            countArr: [1, 1, 1, 2, 2, 3, 2, 1, 3, 3],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/accord/accord_soap.jpg',
          },
          {
            text: '워터리',
            countArr: [1, 2, 3, 1, 1, 1, 1, 2, 2, 2],
            imageURL:
              process.env.PUBLIC_URL +
              '/assets/images/accord/accord_watery.jpg',
          },
          {
            text: '우디',
            countArr: [2, 1, 2, 1, 1, 3, 3, 1, 1, 1],
            imageURL:
              process.env.PUBLIC_URL + '/assets/images/accord/accord_woody.jpg',
          },
        ],
        imageURL: process.env.PUBLIC_URL + '',
      },
    },
  ],
  additional2: [
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'narration',
        text: '제가 잘 이해한 것 같네요!',
      },
    },
    {
      optionType: 'TEXT',
      hasImageSpace: true,
      optionGrid: 'col1',
      data: {
        type: 'narration',
        text: '성향과 관련해 한가지만 더\n여쭤볼게요!',
      },
    },
  ],
};
